<template>
<!-- Footer Start -->
        <div class="container-fluid footer pt-5  wow fadeIn" data-wow-delay="0.1s"
        :class="{'bg-dark text-white50': themeClass === 'dark', 'bg-light text-dark': themeClass === 'light'}">
		<!-- <a href="/perfil?id=0" class="btn btn-primary">Ver los ultimos 5 usuarios</a> -->
            <div class="container py-5">
                <div class="row g-5">
                    <div class="col-lg-3 col-md-6">
                        <h3 class="mb-4"
                        :class="{'text-white50': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
                        >Contacto</h3>
                        <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>En todo el mundo</p>
                        <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
                        <p class="mb-2"><i class="fa fa-envelope me-3"></i>webmaster@sinapsismentoring.com</p>
                        <div class="d-flex pt-2">
                            <a class="btn btn-social" :class="{'btn-outline-light': themeClass === 'dark'}" href=""><i class="fab fa-twitter"></i></a>
                            <a class="btn btn-social" :class="{'btn-outline-light': themeClass === 'dark'}" href=""><i class="fab fa-facebook-f"></i></a>
                            <a class="btn btn-social" :class="{'btn-outline-light': themeClass === 'dark'}" href=""><i class="fab fa-youtube"></i></a>
                            <a class="btn btn-social" :class="{'btn-outline-light': themeClass === 'dark'}" href=""><i class="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="mb-4">Enlaces rapidos</h3>
                        <a class="btn btn-link" href="/">Inicio</a><br/>
						            <a class="btn btn-link" href="#/about">Quienes somos</a><br/>
                        <a class="btn btn-link" href="#/registrarse">Registrarse</a><br/>
                        <a class="btn btn-link" href="#/login">Iniciar Sesion</a><br/>
                      
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="mb-4">Patrocinadores</h3>
                        <div class="row g-2 pt-2">
                            
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <h3 class="mb-4">Novedades</h3>
                        <p>Ahora podes crear mentorias utilizando nuestro servicio de inteligencia artificial</p>
                        <div class="position-relative mx-auto" style="max-width: 400px;">
                            <!-- <input class="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email"> -->
                            <a href="#/registrarse" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">Probar</a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="copyright">
                    <div class="row">
                        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a class="border-bottom" href="https://sinapsismentoring.com">Sinapsis Mentoring</a>, All Right Reserved. 
							
					Designed By <a class="border-bottom" href="mailto:webmaster@sinapsismentoring.com">Sinapsis Mentoring desing</a>
                        </div>
                        <div class="col-md-6 text-center text-md-end">
                            <div class="footer-menu">
                                <a href="">Home</a>
                                <a href="">Cookies</a>
                                <a href="">Help</a>
                                <a href="">FQAs</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer End -->

  
  </template>


<script>
import { inject, computed } from 'vue';
  export default {
    setup() {
      const icons = [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ];
      const themeState = inject("theme");

const themeClass = computed(() => {
  return themeState.theme === "dark"
    ? "dark"
    : "light";
});

  return {
    icons,
    themeClass,
  }
    } 
  }
</script>

<style>
.bg-dark {
  background: linear-gradient(rgb(39, 33, 33), rgb(18, 18, 18));
}
.bg-dark .textocolor {
  color:rgb(216, 218, 219);
}
.bg-light {
  background: linear-gradient(rgb(240 240, 240), rgb(247, 247, 247));
  }
</style>