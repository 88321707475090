<template>
  
 
    
    <form @submit.prevent="submitForm">
      <!-- Agrega el evento @submit.prevent para evitar el envío del formulario por defecto -->
      <v-img
        class="mx-auto my-6"
        max-width="300"
        :src="require('@/assets/logosinapsis.png')"
      ></v-img>

            <v-card
        class="mx-auto pa-12 pb-8"
        :class="{'dark-mode': themeClass === 'dark', 'light-mode': themeClass === 'light'}"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
      
      
        <!-- <v-select
        v-model="rol"  
        :items="items"
          label="Selecciona un rol:"
          outline
          class="username"
        ></v-select> -->
        <div class="text-subtitle-1 text-medium-emphasis"
        :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}">Selecciona un Rol:</div>
        <v-radio-group v-model="rol" :mandatory="true" class="username">
      <v-radio
        v-for="item in items"
        :key="item"
        :label="capitalizeFirstLetter(item)"
        :value="item"
      ></v-radio>
    </v-radio-group>
      

      <div class="text-subtitle-1 text-medium-emphasis"
      :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}">Email:</div>

        <v-text-field
          v-model="username"
          density="compact"
          placeholder="Dirección de correo"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          class="username"
          autocomplete="current-username"
        ></v-text-field>

        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
          :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
        >
          Contraseña

          <!-- <a
            class="text-caption text-decoration-none text-blue"
            href="#"
            rel="noopener noreferrer"
            target="_blank"
          >
            Forgot login password?</a >-->
          
        </div>

        <v-text-field
          v-model="password"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Escribe una contraseña"
          class="password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          autocomplete="current-password"
          @click:append-inner="visible = !visible"
        ></v-text-field>
        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
          :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
        >
          Repetir contraseña
        </div>
        <v-text-field
          v-model="password2"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Repite contraseña"
          class="password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          autocomplete="current-password"
          @click:append-inner="visible = !visible"
        ></v-text-field>
        <v-card class="mb-12" color="surface-variant" variant="tonal">
          <v-card-text class="text-medium-emphasis text-caption"
          :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
          >
            Usa una contraseña que puedas recordar que no sea muy sencilla
          </v-card-text>
        </v-card>

        <v-alert
          v-if="eserror"
          density="compact"
          :text="errorMsg"
          title="Error"
          type="warning"
        ></v-alert>

        <v-alert
          v-if="esalerta"
          density="compact"
          :text="alertaMsg"
          title="Info"
          type="warning"
        ></v-alert>

        <v-btn
          :loading="loading"
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          block
          type="submit"
        >
          Registrarme
        </v-btn>

        <v-card-text class="text-center">
          <a
            class="text-blue text-decoration-none"
            href="#"
            rel="noopener noreferrer"
            target="/login"
          >
            Ir a iniciar sessión <v-icon color="blue" icon="mdi-chevron-right"></v-icon>
          </a>
        </v-card-text>
      </v-card>
    </form>
  

    <div class="mb-4"></div>
</template>

<script>
import Cookies from "js-cookie";
import { ref, getCurrentInstance,inject, watch,computed } from "vue"; // Importa ref
import { useRouter } from "vue-router"; // Importa useRouter
import { useStore } from 'vuex';

export default {
  setup() {
    const loading = ref(false);
    const visible = ref(false);
    //const rol = ref('');
    const username = ref("");
    const password = ref("");
    const password2 = ref("");
    const eserror = ref(false);
    const errorMsg = ref("");
    const esalerta = ref(false);
    const alertaMsg = ref("");
    const token = ref("");
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter(); // Obtiene el enrutador
    const rol = ref('mentor'); // Valor seleccionado por defecto
    const items = ref(['mentor', 'mentee']);

    const toggle = ref(false);
    const themeState = inject("theme");
    const themeClass = computed(() => {
      return themeState.theme === "dark" ? "dark" : "light";
    });
 
    watch(
      themeClass,
      (newClass) => {
        toggle.value = newClass === "light";
      },
      { immediate: true },
    );
    const onToggleChange = () => {
      themeState.theme = themeState.theme === "dark" ? "light" : "dark";
    };

    watch(rol, (newValue) => {
  console.log('Selected role:', newValue);
});
   

    const capitalizeFirstLetter = (items) => {
      return items.charAt(0).toUpperCase() + items.slice(1);
    };
  
    async function submitForm() {
      console.log("Rol:", rol.value); 
      console.log("Username:", username.value);
       console.log("Password:", password.value);
       console.log("Password:", password2.value);
       if (password.value === password2.value) {
        await login(); // Espera a que se complete el inicio de sesión antes de continuar
        
       } else {
        eserror.value = true;
          errorMsg.value = "La contraseña no coincide";
       }
      
    }

    async function login() {
      try {
        loading.value = true;
        const response = await fetch(
          `${proxy.$dominio}/wp-json/custom/v1/register2`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: username.value,
              password: password.value,
              role: rol.value,
            }),
          },
        );
        const data = await response.json();
        //console.log(data.data.token); // Aquí puedes manejar la respuesta de la API
        loading.value = false;
        if (response.ok) {
          // Si la respuesta es exitosa, maneja el token de inicio de sesión
          //token.value = data.data.token; // Asigna el token a la variable token
           //console.log(token.value); // Verifica si token tiene el valor correcto
          //Cookies.set("token", token.value);
         // const user = { name: username.value }; // Ajusta según los datos de tu API
         // store.commit('setAuthenticated', user);
          // Cookies.set("id", data.data.id);
          //router.push("/login"); // Reemplaza '/PagePanel' con la ruta de tu panel de usuario
          esalerta.value = true;
          alertaMsg.value = data.message;
        } else {
          
          // Si la respuesta indica un error de inicio de sesión, muestra la alerta de error
          eserror.value = true;
          errorMsg.value = data.message || "Error desconocido"; // Asigna el mensaje de error proporcionado por la API
          console.error("Error:", data.message); // Muestra el mensaje de error en la consola
        }
      } catch (error) {
        // Si hay un error en la solicitud, muestra la alerta de error
        errorMsg.value = "Error de red. Inténtalo de nuevo.";
        eserror.value = true;
        console.error("Error:", error);
      }
    }

    // Devuelve los datos y los métodos que deseas usar en el template
    return {
      visible,
      rol,
      username,
      password,
      password2,
      eserror,
      errorMsg,
      esalerta,
      alertaMsg,
      submitForm,
      loading,
     items,
     onToggleChange,
     toggle,
     themeClass,
     capitalizeFirstLetter,   
    };
  },
};
</script>

<style scoped>
.dark-mode {
  background-color: #1f1f1f; /* Fondo oscuro */
  color: #ffffff; /* Texto claro */
}

.light-mode {
  background-color: #ffffff; /* Fondo claro */
  color: #1f1f1f; /* Texto oscuro */
}
</style>