import { createStore } from 'vuex';
import Cookies from 'js-cookie';

// Constantes para la expiración de cookies
const COOKIE_EXPIRATION_MINUTES = 720;

const store = createStore({
  state() {
    return {
      // Estado de autenticación
      isAuthenticated: Cookies.get('isAuthenticated') === 'true',
      userName: Cookies.get('userName') || '',
      token: Cookies.get('token') || '',
      id: Cookies.get('id') || '',
      userProfileImage: Cookies.get('userProfileImage') || '',

      // Estado del carrito
      cart: []
    };
  },
  mutations: {
    // Mutaciones de autenticación
    setAuthenticated(state, { user }) {
      state.isAuthenticated = true;
      state.userName = user.name;
      state.token = user.token;
      state.id = user.id;
      state.userProfileImage = user.profileImage;

      Cookies.set('isAuthenticated', true, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
      Cookies.set('userName', user.name, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
      Cookies.set('token', user.token, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
      Cookies.set('userProfileImage', user.profileImage, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
      Cookies.set('id', user.id, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
    },
    clearAuthenticated(state) {
      state.isAuthenticated = false;
      state.userName = '';
      state.token = '';
      state.id = '';
      state.userProfileImage = '';

      Cookies.remove('isAuthenticated');
      Cookies.remove('userName');
      Cookies.remove('userProfileImage');
      Cookies.remove('token');
      Cookies.remove('refresh_token');
      Cookies.remove('id');
    },
    updateProfileImage(state, profileImageUrl) {
      state.userProfileImage = profileImageUrl;
      Cookies.set('userProfileImage', profileImageUrl, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
    },
    updateUserName(state, newUserName) {
      state.userName = newUserName;
      Cookies.set('userName', newUserName, {
        expires: COOKIE_EXPIRATION_MINUTES / (60 * 24), // Convertir minutos a días
      });
    },

    // Mutaciones del carrito
    ADD_TO_CART(state, product) {
      const existingProduct = state.cart.find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += 1;
      } else {
        state.cart.push({ ...product, quantity: 1 });
      }
    },
    REMOVE_FROM_CART(state, productId) {
      state.cart = state.cart.filter(item => item.id !== productId);
    }
  },
  actions: {
    // Acciones de autenticación
    setAuthenticated({ commit }, user) {
      commit('setAuthenticated', { user });
    },
    clearAuthenticated({ commit }) {
      commit('clearAuthenticated');
    },
    updateProfileImage({ commit }, profileImageUrl) {
      commit('updateProfileImage', profileImageUrl);
    },
    updateUserName({ commit }, newUserName) {
      commit('updateUserName', newUserName);
    },

    // Acciones del carrito
    addToCart({ commit }, product) {
      commit('ADD_TO_CART', product);
    },
    removeFromCart({ commit }, productId) {
      commit('REMOVE_FROM_CART', productId);
    }
  },
  getters: {
    // Getters de autenticación
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    userName(state) {
      return state.userName;
    },
    token(state) {
      return state.token;
    },
    id(state) {
      return state.id;
    },
    userProfileImage(state) {
      return state.userProfileImage;
    },

    // Getters del carrito
    cartItems(state) {
      return state.cart;
    },
    cartTotal(state) {
      return state.cart.reduce((total, item) => total + item.price * item.quantity, 0);
    }
  }
});

export default store;




/*   ESTA VERSION ALMACENA LOS DATOS EN LOCALSTORAGE
import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      isAuthenticated: JSON.parse(localStorage.getItem('isAuthenticated')) || false,
      user: JSON.parse(localStorage.getItem('user')) || null,
    };
  },
  mutations: {
    setAuthenticated(state, user) {
      state.isAuthenticated = true;
      state.user = user;
      localStorage.setItem('isAuthenticated', true);
      localStorage.setItem('user', JSON.stringify(user));
    },
    clearAuthenticated(state) {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getUser(state) {
      return state.user;
    },
  },
});

export default store;
*/