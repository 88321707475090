import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import EntradasView from '@/views/EntradasView.vue'
import PageLogin from '@/views/PageLogin.vue'
import PagePanel from '@/views/PagePanel.vue'
import PagePostFull from '@/views/PagePostFull.vue'
import editar from '@/components/panelPerfil/editar.vue'
import registrarse from '@/views/registrarse.vue'
import CursosMentores from '@/views/cursosMentores.vue'
import cart from '@/components/cart.vue'
import Checkout from '@/components/Checkout.vue'
import Carro from '@/components/carro.vue'
import PageIndustria from '@/views/PageIndustria.vue';
import Recoverpasswd from '../components/recoverpasswd.vue'
import EmailVerification from '@/views/EmailVerification.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/verify-email',
    name: 'Verification',
    component: () => import(/* webpackChunkName: "EmailVerification" */ '@/views/EmailVerification.vue')
  },
  {
    path: '/industria',
    name: 'PageIndustria',
    component: () => import(/* webpackChunkName: "PageIndustria" */ '@/views/PageIndustria.vue')
  },
  {
    path: '/recoverpasswd',
    name: 'recoverpasswd',
    component: () => import(/* webpackChunkName: "recoverpasswd" */ '@/components/recoverpasswd.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "cart" */ '@/components/cart.vue')
  },
  {
    path: '/carro',
    name: 'carro',
    component: () => import(/* webpackChunkName: "carro" */ '@/components/carro.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "checkout" */ '@/components/Checkout.vue')
  },
  {
    path: '/entradas/:id',
    name: 'entradas',
    component: () => import(/* webpackChunkName: "entradas" */ '@/views/EntradasView.vue')
  },
  {
    path: '/cursosMentores',
    component: () => import(/* webpackChunkName: "cursosMentores" */ '@/views/cursosMentores.vue'), // Componente principal para ambas rutas
    children: [
      {
        path: '',
        name: 'cursosMentores',
        // Esta ruta se renderiza cuando no hay `id`
      },
      {
        path: ':id',
        name: 'cursos',
        // Esta ruta se renderiza cuando hay un `id`
      }
    ]
  },
  {
    path: '/mentoria/:post',
    name: 'PagePostFull',
    component: () => import('../views/PagePostFull.vue'),
  },
  {
    path: '/editar/:post',
    name: 'editar',
    component: () => import('../components/panelPerfil/editar.vue'),
  },
  {
    path: '/perfil/:id',
    name: 'PagePerfil',
    
    component: () => import(/* webpackChunkName: "about" */ '@/views/PagePerfil.vue')
  },
  {
    path: '/login',
    name: 'PageLogin',
    component: () => import(/* webpackChunkName: "login" */ '@/views/PageLogin.vue')
  },
  {
    path: '/pagepanel',
    name: 'PagePanel',
  component: () => import(/* webpackChunkName: "pagepanel" */ '@/views/PagePanel.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logout" */ '@/views/LogoutView.vue')
  },
  {
    path: '/panelpost',
    name: 'panelpost',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "panelpost" */ '@/views/PagePanelPost.vue')
  },
  {
    path: '/registrarse',
    name: 'registrarse',
    component: () => import(/* webpackChunkName: "registrarse" */ '@/views/registrarse.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

export default router
