<template>    
    <div>
      <!-- <p>El ID recibido es: {{ postId }}</p> -->
      <!-- Resto del contenido del componente -->
    </div>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>{{ stepTitle }}</h3>
          <v-spacer></v-spacer>
          <div v-if="currentStep < 5">Paso {{ currentStep }} de {{ totalSteps - 1 }}</div>
        </v-card-title>
        <v-card-subtitle class="form-step">
          <v-form>
            <div v-if="currentStep === 1">
              <v-text-field v-model="courseTitle" label="Título del curso" :error-messages="[errors.courseTitle]"></v-text-field>
            </div>
            <div v-if="currentStep === 2">
            <!-- Mostrar campos para los temas de clase -->
            <div v-for="(title, index) in 4" :key="index">
              <v-text-field v-model="classTitles[index]" :label="`Tema de clase ${index + 1}`" :error-messages="[errors.courseClass]"></v-text-field>
            </div>
          </div>
            <div v-if="currentStep === 3">
              <v-select v-model="costType" :items="costOptions" label="Costo" :error-messages="[errors.courseOptions]"></v-select>
            </div>
            <div v-if="currentStep === 3 && costType === 'Con costo'">
              <v-text-field v-model="costPrice" label="Precio en moneda de tu país"></v-text-field>
            </div>
            <div v-if="currentStep === 4">
                
    <div ref="editorContainer" class="editor-container" v-html="classContent"></div>
    
  </div>
            <div v-if="currentStep === 5">
              <div class="text-dark">Procesando datos</div>
              <v-text-field v-model="sendingMessage" label="Enviando"></v-text-field>
            </div>
          </v-form>
        </v-card-subtitle>
        <v-card-actions v-if="currentStep !== 5">
          <v-btn @click="prevStep" :disabled="currentStep === 1">Volver</v-btn>
          <v-btn @click="nextStep">
            {{ currentStep === 4 ? 'Actualizar' : 'Siguiente' }}
          </v-btn>
          <v-btn @click="cancelForm" class="ml-2" color="red">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-container> 
  </template>
  
  <script>
  import { ref, onMounted, getCurrentInstance, nextTick} from 'vue';
  import { useRoute } from 'vue-router'; // Para obtener el ID de la publicación desde la ruta
  import { useStore } from 'vuex';
  import Cookies from "js-cookie";
  
  export default {
     props: {
    postId: {
      type: String,
      required: true
    }
  },
  emits: ['close-modal'],

    setup(props, {emit}) {
      const store = useStore();
      const route = useRoute();
      const currentStep = ref(1);
      const totalSteps = 5; // Ajusta el número total de pasos según sea necesario
      const stepTitle = ref('Editar Publicación');
      const courseId = ref('');
      const courseTitle = ref('');
      const classCount = ref(4);
      const classContent = ref('');
      const classTitles = ref(['']); // Cambiado a `ref` para manejar dinámicamente el tamaño del array
      const costType = ref('');
      const costPrice = ref('0');
      const classOptions = ['1', '2', '3', '4'];
      const sendingMessage = ref('');
      const errors = ref({}); // Para gestionar errores
      const costOptions = ['Gratis', 'Con costo'];
      const { proxy } = getCurrentInstance();
      const editor = ref(null);
      const isEditorInitialized = ref(false);
      const editorInstance = ref(null);
      const token = Cookies.get("token");
      
      const closeModal = () => {
      emit('close-modal');
    };
      // Función para cargar los datos de la publicación
      const loadPublicationData = async () => {
        //const postId = route.params.post; // Suponiendo que tienes el ID de la publicación en la ruta
        try {
          const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/edit-post/${props.postId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              // Agrega headers adicionales si es necesario
            }
          });
          const data = await response.json();
          console.log(data);
          if (response.ok) {
            // Inicializar los campos del formulario con los datos de la publicación
            courseId.value = data.id;
            courseTitle.value = data.title;
            classCount.value = data.class_count;
            classTitles.value = data.class_titles || [];
            costType.value = data.cost_type;
            classContent.value = data.post_content;
            costPrice.value = data.price;
                            
          } else {
            console.error('Error al cargar los datos de la publicación:', data.message);
          }
        } catch (error) {
          console.error('Error al cargar los datos de la publicación:', error);
        }
      };
  
      onMounted(() => {
        loadPublicationData();
        console.log('Loading');
      });
  
      const prevStep = () => {
        if (currentStep.value > 1) currentStep.value--;
      };
  
      const nextStep = () => {
        if (currentStep.value < totalSteps) currentStep.value++;
        if (currentStep.value === 4 && !isEditorInitialized.value) {
              initializeEditor();
            }
            if (currentStep.value === 5 ) { finalizeForm(); }    
        
      };
  
      const cancelForm = () => {
        // Lógica para cancelar el formulario
      };
  
      const submitForm = async () => {
  const formData = {
    title: courseTitle.value,
    classCount: classCount.value,
    classTitles: Array.isArray(classTitles.value) ? Array.from(classTitles.value) : [],
    costType: costType.value,
    costPrice: costPrice.value,
    classContent: editor.value ? editor.value.html.get() : ''
  };

  try {
  console.log('Datos del formulario POSTA:', formData);
  
  const response = await fetch(`/wp-json/custom/v1/edit-post2/${props.postId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, 
    },
    body: JSON.stringify(formData)
  });

  // Intentar analizar la respuesta como JSON
  const textResponse = await response.text(); // Primero obtener la respuesta como texto

  try {
    const jsonResponse = JSON.parse(textResponse); // Intentar parsear como JSON
    console.log('JSON Response:', jsonResponse);
    if (response.ok) {
      sendingMessage.value = 'Publicación actualizada con éxito.';
      loadPublicationData();
    } else {
      console.error('Error al actualizar la publicación:', jsonResponse.message);
    }
  } catch (jsonError) {
    // Si no se puede parsear como JSON, mostrar el texto de la respuesta
    console.error('La respuesta no es un JSON válido:', textResponse);
    console.error('Error en JSON.parse:', jsonError);
  }
} catch (error) {
  console.error('Error al actualizar la publicación:', error);
}
  };

const finalizeForm = () => {
  const formData = {
    title: courseTitle.value,
    classCount: classCount.value,
    classTitles: classTitles.value,
    costType: costType.value,
    costPrice: costPrice.value,
    classContent: editor.value ? editor.value.html.get() : ''
  };

  console.log('Datos del formulario:', formData);
  submitForm();  
  sendingMessage.value = 'Formulario enviado';
};


      const initializeEditor = () => {
  nextTick(() => {
    const editorContainer = document.querySelector('.editor-container');
    if (editorContainer) {
      if (editor.value) {
        destroyEditor(); // Asegúrate de destruir cualquier instancia existente del editor
      }
      editor.value = new FroalaEditor(editorContainer, {
        toolbarButtons: {
          'moreText': {
            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
          },
          'moreParagraph': {
            'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
          },
          'moreRich': {
            'buttons': ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR', 'cohere', 'char_counter']
          },
          'moreMisc': {
            'buttons': [ 'insertImage', 'insertFile', 'insertVideo', 'undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help']
          }
                },
  // Archivos de imagenes
                imageUploadURL: '/wp-content/themes/template-de-prueba/js/editor/upload_image.php',
        inlineMode: false,
       language: 'es',
	   charCounterMax: 5000,
     key: '1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==',
     attribution: false, // to hide "Powered by Froala"
//Archivos de texto
	fileUploadURL: '/wp-content/themes/template-de-prueba/js/editor/upload_file.php',
    fileMaxSize: 20 * 1024 * 1024,    //maximo 5mb
//archivos de video
	videoUploadURL: '/wp-content/themes/template-de-prueba/js/editor/upload_video.php',
	videoMaxSize: 50 * 1024 * 1024, //maximo 50mb
        events: {
          'contentChanged': () => {
            // Handle content change if needed
          }
        }
      });
      isEditorInitialized.value = true;
    }
  });
};

onMounted(() => {
        // Cargar CSS
        const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/editor/css/froala_editor.pkgd.css';
      document.head.appendChild(link);
    });

    // Función para cargar scripts secuencialmente
const loadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = callback;
  document.head.appendChild(script);
};

// Ejemplo de uso para cargar múltiples scripts
onMounted(() => {
  // Cargar el script principal de Froala Editor
  loadScript('/editor/froala_editor.min.js', () => {
    // Una vez que Froala Editor se ha cargado, cargar el plugin
    loadScript('/editor/js/plugins/font_size.min.js', () => {
      loadScript('/editor/js/plugins/image.min.js', () => {
        loadScript('/editor/js/plugins/font_family.min.js', () => {
          loadScript('/editor/js/plugins/char_counter.min.js', () => {
            loadScript('/editor/js/plugins/file.min.js', () => {
              loadScript('/editor/js/plugins/video.min.js', () => {
                loadScript('/editor/js/languages/es.js', () => {
                  // Añadir icono y comando personalizado
        FroalaEditor.DefineIcon('cohere', { NAME: 'help', SVG_KEY: 'help' });
        FroalaEditor.RegisterCommand('cohere', {
          title: 'Preguntar a IA',
          focus: false,
          undo: false,
          refreshAfterCallback: false,
          callback: async function () {
            const COHERE_API_KEY = 'GE9s5Jih07NEyTd3AYpNQN8sW9cioRugUAwGbKMj';
            const data = {
              model: 'command-xlarge-nightly',
              prompt: this.selection.text(),
              max_tokens: 556,
              temperature: 0.5,
              k: 1,
              stop_sequences: [],
            };
            // Make the API call to Cohere
            const response = await fetch('https://api.cohere.ai/generate', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${COHERE_API_KEY}`
              },
              body: JSON.stringify(data)
            });
            const result = await response.json();
            // Extract the text from the response
            const suggestions = result.text;
            // Insert the suggestion into selected text
            this.html.insert(suggestions);
          }
        });
      // Una vez que todos los scripts se han cargado, inicializar el editor
      initializeEditor();
    });
    });
    });
    });
    });
  });
    });
  });
});


  
      return {
        currentStep,
        totalSteps,
        stepTitle,
        courseTitle,
        classCount,
        classTitles,
        classOptions,
        costOptions,
        costType,
        costPrice,
        classContent,
        sendingMessage,
        errors,
        prevStep,
        nextStep,
        cancelForm,
        submitForm,
        editor,
        closeModal,
        postId: props.postId,
      };
    },
  };
  </script>
  
