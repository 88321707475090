<template>
   <!-- Mentoria Start -->
<div class="container-xxl py-2">
  <div v-if="loading">Cargando: {{ $route.params.post }}</div>
      <div v-if="error">{{ error }}</div>
      <div v-if="post">
  <div class="container" :class="['bg-' + themeClass, { 'text-white': themeClass === 'dark', 'text-dark': themeClass === 'light', }]">
    <div class="p-3">
      <div class="card mb-3" :class="['bg-' + themeClass, { 'text-white': themeClass === 'dark', 'text-dark': themeClass === 'light', }]" style="border-radius: 5px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
        <h2 class="text-center" style="font-size: 24px;">{{ post.title }}</h2>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12 mb-3"> <!-- Columna izquierda -->
          <div class="card" style="border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);" :class="['bg-' + themeClass, { 'text-green': themeClass === 'dark', 'text-blueoscuro': themeClass === 'light', }]">
            <div class="card-body d-flex flex-column align-items-center">
              <a :href="`/perfil/${post.author.id}`"><img class="rounded-circle flex-shrink-0" :src="post.author.meta.profile_image" alt="" style="width: 180px; height: 180px;"></a>
              <div class="ms-3" style="text-align: center;">
                <h6 class="mt-5 mb-1">{{ post.author.meta.first_name }}</h6>
                <small class="mb-2">Mentor</small>
              </div>
            </div>
            <div style="text-align: left; margin-left: 5px;">
              <ul>
                <p class="card-text " style="margin-bottom: 7px; font-size: 16px;"><strong>Apellido:</strong> {{ post.author.meta.last_name }}</p>
                <p class="card-text" style="margin-bottom: 7px; font-size: 16px;">
  <strong>Costo:</strong>
  {{ post.price.trim() !== '' ? post.price : 'Gratis' }}
</p>
                <p class="card-text " style="margin-bottom: 7px; font-size: 16px;"><strong>Ubicación:</strong> {{ post.author.meta.user_ubicacion }}</p>
                <p class="card-text " style="margin-bottom: 7px; font-size: 16px;"><strong>Idiomas:</strong> {{ post.author.meta.user_idioma }}</p>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-12 mb-3"> <!-- Columna del medio -->
          <div class="card h-100" :class="['bg-' + themeClass, { 'text-white': themeClass === 'dark', 'text-dark': themeClass === 'light', }]" style="border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
            <div class="post-container" style="position: relative; margin: 15px;">
              <div class="additional-info " :class="['bg-' + themeClass, { 'text-dark-pink': themeClass === 'dark', 'text-blueoscuro': themeClass === 'light', }]" style="float: right; width: 50%; background: white; padding: 10px; box-shadow: 0 2px 4px rgba(0,0,0,0.1); margin-left: 15px;">
                <h4>Valoraciones</h4>
                <p>⭐⭐⭐⭐⭐</p>
                <button @click="likePost" class="btn btn-primary">Me Gusta</button>
                <!-- <img src="/ruta-del-logo.png" alt="Logo Empresa" style="width: 100px; height: auto; display: block; margin-top: 10px;"> -->
                <button @click="sharePost" class="btn btn-secondary" style="margin-top: 0px;">Compartir</button>
                <h4 style="margin-top: 10px; text-decoration: underline;">Temario:</h4>
    <!-- Verifica si hay datos en filteredClassTitles -->
    <p v-if="filteredClassTitles.length === 0">No hay datos disponibles</p>
    <p v-else v-for="(classTitle, index) in filteredClassTitles" :key="index">
      <strong>Modulo {{ index + 1 }}:</strong>
      {{ classTitle }}
    </p>
              </div>
             <div class="post-description" v-html="cleanedContent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mentoria Fin -->
  </div>

  </template>
  
  <script setup>
import { ref, watch, getCurrentInstance, inject,computed,onMounted } from 'vue';
import { useRoute } from 'vue-router';

const post = ref(null);
const loading = ref(true);
const error = ref('');
const route = useRoute();
const cleanedContent = ref('');
const { proxy } = getCurrentInstance();
const themeState = inject("theme");
const themeClass = computed(() => {
  return themeState.theme === "dark"
    ? "dark"
    : "light";
});

const cleanContent = (html) => {
  // Eliminar todos los estilos que contienen width: 600px
  return html.replace(/width\s*:\s*600px\s*;?/gi, 'width: 100%');
};


const filteredClassTitles = computed(() => {
  // Asegúrate de que post.value.class_titles sea un array
  const titles = post.value.class_titles || [];
  return Array.isArray(titles) ? titles.filter(title => title.trim() !== '') : [];
});

const fetchPost = async () => {
  const postSlug = route.params.post; // Obtiene el slug de la ruta actual
  try {
    const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/mentoria/${postSlug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Agrega headers adicionales si es necesario
      }
    });

    if (!response.ok) {
        error.value = '';
      // Diferencia entre códigos de error específicos
      switch (response.status) {
        case 401:
          throw new Error('No autorizado: Verifica tus credenciales o token.');
        case 403:
          throw new Error('Prohibido: No tienes permiso para acceder a este recurso.');
        case 404:
          throw new Error('Post No encontrado: Verifica el nombre de la publicación.');
        case 500:
          throw new Error('Error interno del servidor: Inténtalo de nuevo más tarde.');
        default:
          throw new Error(`Error inesperado: ${response.statusText}`);
      }
    }
    error.value = '';
    const data = await response.json();
    cleanedContent.value = cleanContent(data.content);
    post.value = data;

  } catch (err) {
    error.value = '';
    // Manejo de errores específicos
    if (err.name === 'TypeError' && err.message.includes('Failed to fetch')) {
      error.value = 'Error de red: No se pudo conectar al servidor.';
    } else {
      error.value = err.message;
    }
  } finally {
    loading.value = false;
  }
};

// Llama a fetchPost cuando se monta el componente o cuando cambia el parámetro de la ruta
watch(() => route.params.post, fetchPost, { immediate: true });
</script>

  
  <style scoped>
.text-dark-pink {
  color: #019526; /* Ajusta el color según tu preferencia */
}
.text-dark-naranja {
  color: #019526; /* Ajusta el color según tu preferencia */
}.text-blueoscuro {
  color: #441aae; /* Ajusta el color según tu preferencia */
}
.post-description video {
  max-width: 100%;
}
  </style>
  