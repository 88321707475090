<template>
    <div class="container-xxl py-2">
      <div class="container">
        <div :class="{'dark-mode': themeClass === 'dark', 'light-mode': themeClass === 'light'}">
          <div>
            <div>
              <div class="banner">
                <p class="banner-text">Accedé a las Herramientas de inteligencia artificial</p>
                <p class="banner-text text-orange">En Sinapsis Mentoring te ayudamos a crear contenido de calidad para tus estudiantes</p>
              </div>
              <div>
                <p class="banner-text text-orange">Lista de Herramientas</p>
                <ul class="course-list">
                  <li class="course-card" @click="openModal">
                    <div class="image-container">
                      <img :src="imageSrcBanner" alt="Herramientas ia" />
                      <div class="course-overlay">
                        <div class="course-title">Remover fondo de imagenes</div>
                        <div class="course-price">Gratis para mentores</div>
                      </div>
                    </div>
                  </li>
                </ul>
  
                <!-- Custom Modal -->
                <CustomModal
                  :showModal="modalVisible"
                  :modalSize="'modal-lg'"
                  @update:showModal="modalVisible = $event"
                />
                <ul class="course-list mt-3">
                  <li class="course-card" @click="openModal2">
                    <div class="image-container">
                      <img :src="imageSrcBanner2" alt="Herramientas ia" />
                      <div class="course-overlay">
                        <div class="course-title">Traductor de español - inglés</div>
                        <div class="course-price">Gratis para mentores</div>
                      </div>
                    </div>
                  </li>
                </ul>
  
                <!-- Custom Modal -->
                <CustomModal2
                  :showModal2="modalVisible2"
                  :modalSize="'modal-lg'"
                  @update:showModal2="modalVisible2 = $event"
                />

                <ul class="course-list mt-3">
                  <li class="course-card" @click="openModal3">
                    <div class="image-container">
                      <img :src="imageSrcBanner3" alt="Herramientas ia" />
                      <div class="course-overlay">
                        <div class="course-title">Texto a voz</div>
                        <div class="course-price">Gratis para mentores</div>
                      </div>
                    </div>
                  </li>
                </ul>
  
                <!-- Custom Modal -->
                <CustomModal3
                  :showModal3="modalVisible3"
                  :modalSize="'modal-lg'"
                  @update:showModal3="modalVisible3 = $event"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script setup>
import { ref, computed, watch, getCurrentInstance,inject } from 'vue';
import CustomModal from '@/components/ia/quitaFondo.vue';
import CustomModal2 from '@/components/ia/traductor-es-en.vue';
import CustomModal3 from '@/components/ia/text-to-voice.vue';

const modalVisible = ref(false);
const modalVisible2 = ref(false);
const modalVisible3 = ref(false);

const openModal = () => {
  modalVisible.value = true;
};
const openModal2 = () => {
  modalVisible2.value = true;
};
const openModal3 = () => {
  modalVisible3.value = true;
};

// Obtener la instancia actual
const { proxy } = getCurrentInstance();

// Imagen del banner
const imageSrcBanner = computed(() => `${proxy.$dominio}/wp-content/themes/template-de-prueba/img/picture.webp`);
const imageSrcBanner2 = computed(() => `${proxy.$dominio}/wp-content/themes/template-de-prueba/img/traducir.webp`);
const imageSrcBanner3 = computed(() => `${proxy.$dominio}/wp-content/themes/template-de-prueba/img/text-to-voice.webp`);

const toggle = ref(false);
const themeState = inject("theme");

const themeClass = computed(() => {
  return themeState.theme === "dark" ? "dark" : "light";
});

watch(
  themeClass,
  (newClass) => {
    toggle.value = newClass === "light";
  },
  { immediate: true }
);

const onToggleChange = () => {
  themeState.theme = themeState.theme === "dark" ? "light" : "dark";
};
</script>

  
  <style scoped>
  /* Mantén tu importación de Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

/* Lista de cursos */
.course-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center; /* Centra las tarjetas para pantallas pequeñas */
}

/* Tarjetas de cursos */
.course-card {
  position: relative;
  width: 100%; /* Cambiar a 100% para que se ajuste al contenedor */
  max-width: 400px; /* Ancho máximo */
  height: auto; /* Permitir que la altura se ajuste */
  border-radius: 8px;
  overflow: hidden;
  flex: 1; /* Las tarjetas ocuparán todo el ancho disponible en móviles */
}
.course-card {
  cursor: pointer;
}

.modal {
    z-index: 100000;
}
.course-link {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: inherit;
}

.course-link:hover {
  text-decoration: underline; 
}

.image-container {
  width: 100%; /* Cambiar a 100% para que se ajuste al contenedor */
  height: auto;
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%; /* Imagen se ajustará al ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  object-fit: cover; /* Ajusta la imagen al contenedor sin distorsionar */
}

.course-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5); /* Fondo negro transparente */
  color: white;
  text-align: center;
}

.course-title {
  font-size: 16px;
  font-weight: bold;
}

.course-price {
  font-size: 16px;
  text-decoration: overline;
  color: rgb(255, 0, 0); /* Color rojo para el precio */
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con 50% de opacidad */
  padding: 4px; /* Opcional: espacio alrededor del texto */
  border-radius: 4px;
}

/* Banner de promoción */
.banner {
  background-color: #e0f7fa; /* Celeste claro */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  border-radius: 4px;
}

.banner-text {
  color: rgb(4, 4, 4);
  font-size: 24px;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  z-index: 2;
}

.banner-image {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: cover;
  z-index: 1;
}

.banner-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
}

.banner-button:hover {
  background-color: #0056b3;
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
  .banner {
    padding: 20px;
  }

  .banner-text {
    font-size: 20px; /* Ajusta el tamaño del texto */
  }

  .banner-image {
    width: 100%; /* Imagen ocupará todo el ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
  }

  .banner-button {
    margin-top: 20px;
    width: 100%; /* Botón ocupa todo el ancho del contenedor */
  }

  .course-card {
    width: 100%; /* Las tarjetas ocuparán todo el ancho disponible */
  }

  .course-title {
    font-size: 14px; /* Texto más pequeño en pantallas medianas */
  }

  .course-price {
    font-size: 14px;
  }
}

/* Media Queries para pantallas muy pequeñas */
@media (max-width: 480px) {
  .banner-text {
    font-size: 18px; /* Ajusta el tamaño del texto en pantallas muy pequeñas */
  }

  .banner-button {
    font-size: 14px;
  }

  .course-title {
    font-size: 12px; /* Texto más pequeño en pantallas pequeñas */
  }

  .course-price {
    font-size: 12px;
  }
}

</style>