<template>
  <div class="container mt-4">
    <h1 class="mb-4">Productos o servicio en venta</h1>
    <div class="row">
      <div class="col-md-4 mb-4" v-for="product in products" :key="product.id">
        <div class="card">
          <img :src="product.images[0]?.src" :alt="product.name" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title">{{ product.name }}</h5>
            <p class="card-text">{{ product.short_description }}</p>
            <p class="card-text"><strong>Precio:</strong> {{ formatCurrency(product.price) }}</p>
            <button @click="addToCart(product)" class="btn btn-primary">Agregar al carrito</button>
          </div>
        </div>
      </div>
    </div>
    <button @click="viewCart" class="btn btn-success mt-4">Ver carrito de compra</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const products = ref([]);

const fetchProducts = async () => {
  try {
    const response = await fetch('https://sinapsismentoring.com/wp-json/wc/v3/products', {
      method: 'GET',
      headers: {
        'Authorization': 'Basic ' + btoa('ck_c519ea0e1ef45b32a684fabf57d5902ee8c44a43:cs_634e79a160dd6f6022ed0f5328e4d6b6455d092a')
      }
    });
    if (!response.ok) throw new Error('Network response was not ok');
    products.value = await response.json();
    console.log(products.value);
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

const addToCart = (product) => {
  store.dispatch('addToCart', product);
  console.log('Added to cart');
};

const viewCart = () => {
  router.push('/carro');
};

// Function to format currency
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

onMounted(() => {
  fetchProducts();
});
</script>

<style scoped>
.card-img-top {
  height: 200px;
  object-fit: cover;
}
</style>
