<template>
    <div>
      <form @submit.prevent="submitForm">
        <!-- Logo -->
        <v-img
          class="mx-auto my-6"
          max-width="300"
          :src="require('@/assets/logosinapsis.png')"
        ></v-img>
  
        <!-- Card para la recuperación de contraseña -->
        <v-card
          class="mx-auto pa-12 pb-8"
          :class="{'dark-mode': themeClass === 'dark', 'light-mode': themeClass === 'light'}"
          elevation="8"
          max-width="448"
          rounded="lg"
        >
          <div 
            class="text-subtitle-1 text-medium-emphasis"
            :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
          >
            Recuperar Contraseña
          </div>
  
          <!-- Campo de correo -->
          <v-text-field
            v-model="email"
            density="compact"
            placeholder="Dirección de correo"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            class="email"
            autocomplete="email"
          ></v-text-field>
  
          <!-- Información adicional -->
          <v-card class="mb-12" color="surface-variant" variant="tonal">
            <v-card-text 
              class="text-medium-emphasis text-caption"
              :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
            >
              Ingresa tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña.
            </v-card-text>
          </v-card>
  
          <!-- Mensaje de error -->
          <v-alert
            v-if="eserror"
            density="compact"
            :text="errorMsg"
            title="Error"
            type="warning"
          ></v-alert>
  
          <!-- Botón de envío -->
          <v-btn
            :loading="loading"
            class="mb-8"
            color="blue"
            size="large"
            variant="tonal"
            block
            type="submit"
          >
            Enviar Enlace de Recuperación
          </v-btn>
  
          <!-- Enlace para iniciar sesión -->
          <v-card-text class="text-center">
            <a
              class="text-blue text-decoration-none"
              href="#/login"
              rel="noopener noreferrer"
              
            >
              Iniciar Sesión <v-icon color="blue" icon="mdi-chevron-right"></v-icon>
            </a>
          </v-card-text>
        </v-card>
      </form>
    </div>
  </template>
  
  <script setup>
  import { ref, inject, computed, watch } from 'vue';   

  // Variables de estado
  const email = ref('');
  const loading = ref(false);
  const eserror = ref(false);
  const errorMsg = ref('');
  const toggle = ref(false);
    const themeState = inject("theme");
    const themeClass = computed(() => {
      return themeState.theme === "dark" ? "dark" : "light";
    });
 
    watch(
      themeClass,
      (newClass) => {
        toggle.value = newClass === "light";
      },
      { immediate: true },
    );
    const onToggleChange = () => {
      themeState.theme = themeState.theme === "dark" ? "light" : "dark";
    };

  // Manejar el envío del formulario
  const submitForm = async () => {
    loading.value = true;
    eserror.value = false;

    try {
      console.log(email.value);
      // Realizar la llamada fetch al endpoint real
      const response = await fetch('https://sinapsismentoring.com/wp-json/custom/v1/send-reset-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email.value }),
      });

      // Parsear la respuesta
      const result = await response.json();

      if (response.ok) {
        // Suponiendo que la llamada fue exitosa
        loading.value = false;
        alert('El código de recuperación ha sido enviado a tu correo electrónico.');
      } else {
        // Manejo de errores si la respuesta no es OK
        loading.value = false;
        eserror.value = true;
        errorMsg.value = result.data || 'Error al enviar el código de recuperación. Inténtalo de nuevo.';
      }
    } catch (error) {
      // Manejo de errores en caso de que la llamada fetch falle
      loading.value = false;
      eserror.value = true;
      errorMsg.value = 'Error de red. Inténtalo de nuevo.';
    }
  };
</script>

  
  <style scoped>
  .dark-mode {
    background-color: #333;
    color: white;
  }
  
  .light-mode {
    background-color: white;
    color: black;
  }
  </style>
  