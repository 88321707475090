<template>
  <!-- Navbar Start -->
  <nav
    class="navbar navbar-expand-lg fixed-top"
    :class="['navbar-' + themeClass, 'bg-' + themeClass]"
  >
    <!-- Container wrapper -->
    <div class="container-xxl">
      <!-- Navbar brand -->
      <a class="navbar-brand" href="#">
        <img
          src="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/icon.png"
          height="50"
          width="50"
          alt="icono"
          loading="lazy"
        />
              </a>
              <div class="navbar-brand"> <span>{{ tituloBlog }}</span> </div>
      <!-- Toggle button -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      
      </button>

      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Left links -->
        <div class="nav-item" @click="closeMenu">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <v-list-item
          @click="$router.push('/cursosMentores')"
          title="Cursos"
          value="Cursos"
        ></v-list-item>
        </li>
          <li class="nav-item">
            <v-list-item
          @click="$router.push('/registrarse')"
          title="Registrarse"
          value="Registrarse"
        >
        <!-- 
        prepend-icon="mdi-information-outline"
        prepend-icon="mdi-home-city" -->
      </v-list-item>
          </li>
          <li class="nav-item">
            <v-list-item
          @click="$router.push('/about')"
          title="Quienes somos"
          value="Quienes somos"
        ></v-list-item>
        </li>
        </ul>
      </div>
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="d-flex align-items-center">
            <span class="me-2"> <i class="fas fa-moon"></i></span>
            <v-switch
              v-model="toggle"
              @change="onToggleChange"
              class="switch-align"
            ></v-switch>
            <span class="ms-2"><i class="fas fa-sun"></i> </span>
          </li>
        </ul>
        <!-- Search form -->
        
        <form 
        @submit.prevent="searchMentorias"
        @keyup.enter="searchMentorias"
        class="d-flex input-group w-auto">
          <input
          v-model="query" 
          type="search"
            class="form-control"
            placeholder="Consulta mentoria"
            aria-label="Buscar"
          />
          <v-btn
            class="btn btn-outline-primary ml-1"
            :loading="loading"
            type="button"
            @click="searchMentorias"
            data-mdb-ripple-color="dark"
            style="padding: 0.45rem 1.5rem 0.35rem"
          >
            Buscar
        </v-btn>
        </form>
        

        <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Resultados de la búsqueda</v-card-title>
        <v-card-text>
          <v-list v-if="posts.length">
            <v-list-item v-for="post in posts" :key="post.id">
              <v-list-item-title>
                  <a :href="post.link" target="_blank">{{ post.title.rendered }}</a>
                </v-list-item-title>
                <v-list-item-subtitle v-html="post.excerpt.rendered"></v-list-item-subtitle>
             </v-list-item>
          </v-list>
          <v-alert v-else type="info">No se encontraron resultados.</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


        <ul class="navbar-nav mb-2 mb-lg-0">
          
             <!-- <li v-if="isAuthenticated" class="nav-item">Logout</li>
      <li v-else class="nav-item">Login</li>  -->
      
      <li class="mt-3" v-if="isAuthenticated2">Bienvenido!</li>
      <li v-else>
        <button
            class="btn btn-primary ml-2"
            type="button"
            data-mdb-ripple-color="dark"
            style="padding: 0.45rem 1.5rem 0.35rem"
            @click="login"
          >
          <div class="nav-item" @click="closeMenu">Iniciar Sesión</div>
          </button>
      </li>
          <!-- Navbar dropdown -->
          <li v-if="isAuthenticated2" class="nav-item dropdown" @click="toggleDropdown">
            <a
              class="nav-link dropdown-toggle hidden-arrow"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fas fa-bell mt-custom"></i>
            </a>
            <!-- Dropdown menu -->
            <ul
              class="dropdown-menu dropdown-menu-end notifications-list p-1"
              aria-labelledby="navbarDropdown"
              :class="[
        'bg-' + themeClass, {
        'text-light': themeClass === 'dark',
        'text-dark': themeClass === 'light', },
        { show: dropdownOpen }
      ]"
            >
              <li v-for="(item, index) in notifications" :key="index">
                <div class="row">
                  <div class="col-md">
                    <img
                      :src="item.imgSrc"
                      height="63"
                      width="auto"
                      class="d-block"
                      alt="..."
                    />
                  </div>
                  <div class="col-md">
                    <p class="h6 mb-0">Notificaciones</p>
                    <p class="h6 mb-1">{{ item.title }}</p>
                    <span class="small">{{ user }}</span>
                  </div>
                </div>
                <hr
                  class="dropdown-divider"
                  v-if="index < notifications.length - 1"
                />
              </li>
            </ul>
          </li>
          <li v-if="isAuthenticated2" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style="margin-left: 15px;"
            >
            <div v-if="userProfileImage">
              <img
  :src="userProfileImage"
  class="rounded-circle"
  :style="{ width: '40px', height: '40px' }"
  alt="User Profile Image"
/>
  </div>
  <div v-else>
    Menú
  </div>
            </a>
            <!-- Dropdown menu -->
            <ul
              class="dropdown-menu dropdown-menu-end p-1"
              aria-labelledby="navbarDropdown"
              :class="[
        'bg-' + themeClass, {
        'text-light': themeClass === 'dark',
        'text-dark': themeClass === 'light', }]"
              >
              <!-- <li class="my-2 d-flex align-items-center">
                <img
                  src="https://mdbootstrap.com/img/Photos/Avatars/img%20(4).jpg"
                  class="rounded-circle img-fluid me-1"
                  height="25"
                  width="25"
                /><span> User 1</span>
              </li> -->
              <li class="nav-item">
            <v-list-item
          @click="() => { $router.push('/PagePanel'); closeMenu(); }"
          title="Mi Cuenta"
          value="Mi Cuenta"
        ></v-list-item>
        </li>
        <li class="nav-item">
            <v-list-item
          @click="() => { $router.push('/about'); closeMenu(); }"
          title="Ayuda"
          value="Ayuda"
        ></v-list-item>
        </li>
        <hr class="dropdown-divider" />
        <li class="nav-item">
            <v-list-item
          @click="() => { desloguear(); closeMenu(); }"
          title="Cerrar Sesión"
          value="Cerrar Sesión"
        ></v-list-item>
        </li>
            
          
            </ul>
          </li>
        </ul>
      </div>
      <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
  </nav>
  <!-- Navbar -->
</template>

<script>
import Cookies from "js-cookie"; // Importa Cookies desde 'js-cookie'
import logo from "../assets/logo.png";
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import {
  ref,
  onMounted,
  getCurrentInstance,
    inject,
  computed,
  watch,
  } from "vue";

export default {
  name: "Navbar",
  props: {
    themeGlobal: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const nombreDeUsuario = ref('');
    const token = ref('');
    const isAuthenticated = ref(false);
    const profile_image = ref('')
    const toggle = ref(false);
    const tituloBlog = ref('');
    const themeState = inject("theme");
    const store = useStore();
    const query = ref('');
    const posts = ref([]);
    const dialog = ref(false);
    const loading = ref(false);
    const { proxy } = getCurrentInstance();
    
    const searchMentorias = async () => {
      if (query.value.trim()) {     
      try {
        loading.value = true;
    const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/posts?search=${query.value}`);
        if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    //console.log(query.value);
    //console.log(data);
    posts.value = data.length ? data : [];
    dialog.value = true;
    loading.value = false;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    posts.value = [];
    dialog.value = true;
    loading.value = false;
      }
      }
};

    const isAuthenticated2 = computed(() => store.getters.isAuthenticated);
    
    const user = computed(() => store.getters.userName);
    const userProfileImage = computed(() => store.getters.userProfileImage);
//funcion para detectar el theme
    const themeClass = computed(() => {
      return themeState.theme === "dark" ? "dark" : "light";
    });
 
    watch(
      themeClass,
      (newClass) => {
        toggle.value = newClass === "light";
      },
      { immediate: true },
    );
     
    const onToggleChange = () => {
      themeState.theme = themeState.theme === "dark" ? "light" : "dark";
    };

    //funcion para desloguear
    const desloguear = () => {
      store.commit('clearAuthenticated');
      router.push("/");
    };

//FUNCION PARA EL MENU DE USUARIO
const verificarAutenticacion = async () => {
  try {
    const token = Cookies.get("token");

    if (token) {
      // Si hay un token, el usuario está autenticado
      isAuthenticated.value = true;

      // Obtener la información del usuario utilizando el token
      const respuesta = await fetch(
        `${proxy.$dominio}/wp-json/wp/v2/users/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (respuesta.ok) {
        const data = await respuesta.json();
        // Suponiendo que la respuesta contiene el nombre del usuario
        profile_image.value = data.meta.profile_image;
        nombreDeUsuario.value = data.meta.first_name;
        // avatar.value = data.avatar_urls["24"];
        //console.log(data);
       // console.log(profile_image.value);
      } else {
        const errorData = await respuesta.json();
        if (errorData.code === 'rest_not_logged_in') {
          // console.error('No estás conectado:', errorData.message);
          console.log('No estás logueado. Por favor, inicia sesión.');
        } else {
          console.error(
            "Error al obtener la información del usuario:",
            respuesta.status,
            errorData.message
          );
          console.log("Error al obtener la información del usuario. Intenta nuevamente.");
        }
      }
    } else {
      // Si no hay token, el usuario no está autenticado
      isAuthenticated.value = false;
      // alert("Cerrada la sesión, vuelve a iniciarla.");
      // router.push("/login");
    }
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error("Error de red:", error);
      alert("Error de red. Intenta nuevamente.");
    }
  }
};

onMounted(verificarAutenticacion);
//FIN DE FUNCION PARA MENU USUARIO





    const getTituloBlog = async () => {
      try {
        // Consulta para obtener el título del blog
        const blogResponse = await fetch(`${proxy.$dominio}/wp-json/`);
        const blogData = await blogResponse.json();

        // Asignar el título del blog a la variable reactiva
        tituloBlog.value = blogData.name;
      } catch (error) {
        console.error(error);
      }
    };
    const login = () => {
      router.push('/login');
    };
    onMounted(getTituloBlog);

    return {
      tituloBlog,
      themeClass,
      logo,
        toggle,
      onToggleChange,
      isAuthenticated,
      profile_image,
      token,
      user,
      isAuthenticated2,
      login,
      themeGlobal: props.themeGlobal,
      user,
      userProfileImage,
      desloguear,
      searchMentorias,
      dialog,
      query,
      posts,
      loading,
        };
  },

  data() {
    return {
      TituloBlog: "Mejores Mentores",
      isLoggedIn: false,
      drawer: false,
      menu: false,
      dropdownOpen: false,
      notifications: [
        {
          imgSrc: "https://mdbootstrap.com/img/Photos/Slides/img%20(15).jpg",
          title: "Destacadas",
        },
        // Agrega más notificaciones según sea necesario
      ],
    };
  },
  methods: {
   
    closeMenu() {
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  },

  

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    mostrarVariable() {
      //console.log(this.TituloBlog); // Accediendo a la variable en un método
    },
    modificarVariable() {
      this.TituloBlog = "¡La otra variable ha sido modificada!"; // Modificar otraVariable
    },
  },
  
};


</script>

<style>
.nav-link.dropdown-toggle::after {
  margin-left: 15px; /* Ajusta el margen a tu preferencia */
}
.navbar .navbar-brand img {
  margin-top: 0; /* Asegura que no haya margen superior */
  margin-bottom: 0; /* Asegura que no haya margen inferior */
  vertical-align: middle; /* Alinea verticalmente la imagen */
}

.navbar .container-xxl {
  display: flex;
  align-items: center; /* Alinea verticalmente los elementos del navbar */
  /*height: 56px; /* Ajusta este valor según la altura de tu navbar */
}

.navbar-nav .nav-item,
.navbar-nav .nav-link {
  padding: 0; /* Elimina cualquier padding adicional */
  margin: 0; /* Elimina cualquier margen adicional */
}

.d-flex.align-items-center {
  height: 100%;
}

.switch-align {
  margin: 0; /* Elimina margen adicional */
  padding: 0; /* Elimina padding adicional */
  height: auto; /* Asegura que el switch no sea más alto que el navbar */
}

@media (max-width: 767.98px) {
  .navbar .navbar-nav {
    margin-top: 0; /* Asegúrate de que no haya margen superior en móviles */
  }
}
.switch-align {
  display: inline-flex;
  align-items: center;
}
.fa-sun {
  color: #ffd700; /* Color dorado para el sol */
}

.fa-moon {
  color: #1e90ff; /* Color azul para la luna */
}
.mt-custom {
    margin-top: 20px; /* Ajusta el tamaño del margen según lo necesites */
}
</style>
