<template>
    <v-card class="app-container">
    <v-layout class="v-card-custom">
      <v-navigation-drawer v-model="drawer" :rail="rail" permanent 
      :class="{'bg-dark text-light': themeState.theme === 'dark', 'bg-light text-dark': themeState.theme === 'light'}">
        <v-list-item v-if="isAuthenticated">
          <div class="profile-container">
            <div v-if="userProfileImage" class="profile-image-container">
              <img
                :src="userProfileImage"
                alt="Profile Image"
                class="profile-image"
              />
            </div>
            <span class="username">{{ nombreDeUsuario }}</span>
          </div>
        </v-list-item>
        <v-list-item v-else title="Iniciar sesión" nav></v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" permanent nav>
          <v-list-item
            @click="dashboard"
            prepend-icon="mdi-home-city"
            title="Dashboard"
            value="Dashboard"
          ></v-list-item>
          <v-list-item
            @click="perfil"
            prepend-icon="mdi-account"
            title="Mis datos"
            value="Mis datos"
          ></v-list-item>
          <v-list-item
            @click="crearmentoria"
            prepend-icon="mdi-pencil-plus-outline"
            title="Crear Mentorias"
            value="Crear mentoria"
          ></v-list-item>
          <v-list-item
            @click="editar"
            prepend-icon="mdi-file-edit-outline"
            title="Editar Mentorias"
            value="Editar mentoria"
          ></v-list-item>
          <v-list-item
            @click="mensajesCall"
            prepend-icon="mdi-chat"
            title="Mensajes"
            value="Mensajes"
          ></v-list-item>
          <v-list-item
            @click="claseVirtual"
            prepend-icon="mdi-headphones"
            title="Clases virtuales"
            value="Mentorias virtuales"
          ></v-list-item>
          <v-list-item
            @click="herramientasIA"
            prepend-icon="mdi-robot-outline"

            title="Herramientas IA"
            value="Herramientas IA"
          ></v-list-item>
        </v-list>
        <v-btn @click="toggleRail" class="toggle-rail-btn" icon>
  <!-- Mostrar el texto y la flecha cuando el menú está cerrado -->
  <template v-if="!rail">
    <v-icon>mdi-chevron-left</v-icon>
  
  </template>
  
  <!-- Mostrar solo la flecha cuando el menú está abierto -->
  <template v-else>
    <v-icon>mdi-chevron-right</v-icon>
  </template>
</v-btn><span class="toggle-text" v-if="!rail" style="margin-left: 8px;">Deslizar Menú</span>

      </v-navigation-drawer>
      <v-main class="v-main-custom"
      :class="{'bg-dark text-light': themeState.theme === 'dark', 'bg-light text-dark': themeState.theme === 'light'}">
        <component :is="currentComponent"></component>
      </v-main>
    </v-layout>
  </v-card>
</template>

<script setup>
import { ref, markRaw, getCurrentInstance, inject, computed } from "vue";
import { useStore } from 'vuex';
import Cookies from "js-cookie"; // Importa Cookies desde 'js-cookie'

import dash from "@/components/panelPerfil/dashboard.vue";
import panelPerfil from "@/components/panelPerfil/panelPerfil.vue";
import crear from "@/components/panelPerfil/crear.vue";
import mensajes from "@/components/panelPerfil/mensajes.vue";
import MisMentorias from "@/views/PagePanelPost.vue";
import Clasesvirtuales from "@/components/panelPerfil/clasesVirtuales.vue";
import herramientas from "@/components/panelPerfil/herramientasIA.vue";

const { proxy } = getCurrentInstance();
const drawer = ref(true);
const rail = ref(false);
const currentComponent = ref(markRaw(dash));
const isAuthenticated = computed(() =>store.getters.isAuthenticated);
//const nombreDeUsuario = ref("");
const profileImageUrl = ref(null);
const store = useStore();
const userProfileImage = computed(() => store.getters.userProfileImage);
const nombreDeUsuario = computed(() => store.getters.userName);

    
// Inyectar el estado
const themeState = inject('theme');
// Computed para clase del tema
const themeClass = computed(() => {
  return themeState.theme === 'dark' ? 'dark' : 'light';
});

const mensajesCall = () => {
  currentComponent.value = markRaw(mensajes);
  //console.log("Mensajes");
};

const herramientasIA = () => {
  currentComponent.value = markRaw(herramientas);
  //console.log("Creando");
};
const claseVirtual = () => {
  currentComponent.value = markRaw(Clasesvirtuales);
  //console.log("Creando");
};

const crearmentoria = () => {
  currentComponent.value = markRaw(crear);
  //console.log("Creando");
};

const editar = () => {
  currentComponent.value = markRaw(MisMentorias);
  // console.log("Editando");
};

const dashboard = () => {
  currentComponent.value = markRaw(dash);
  // console.log("dashboard");
};

const perfil = () => {
  currentComponent.value = markRaw(panelPerfil);
  //console.log("perfil");
};

const toggleRail = () => {
  rail.value = !rail.value;
};

// Función para verificar la autenticación y obtener la información del usuario
const verificarAutenticacion = async () => {
  try {
    const token = Cookies.get("token");
    if (token) {
      // Si hay un token, el usuario está autenticado
      isAuthenticated.value = true;

      // Obtener la información del usuario utilizando el token
      const respuesta = await fetch(
        `${proxy.$dominio}/wp-json/wp/v2/users/me`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (respuesta.ok) {
        const data = await respuesta.json();
        profileImageUrl.value = data.meta.profile_image;
        // Suponiendo que la respuesta contiene el nombre del usuario
        nombreDeUsuario.value = data.meta.first_name;
        //avatar.value = data.avatar_urls["24"];

        //  console.log(avatar.value);
      } else {
        console.error(
          "Error al obtener la información del usuario:",
          respuesta.status,
        );
        alert("Cerrada la seccion, vuelve a iniciarla.");
      }
    } else {
      // Si no hay token, el usuario no está autenticado
      isAuthenticated.value = false;
      alert("Cerrada la seccion, vuelve a iniciarla.");
     // router.push("/login");
    }
  } catch (error) {
    console.error("Error de red:", error);
  }
};

// Llamar a la función de verificación de autenticación al montar el componente
//verificarAutenticacion();
</script>

<style scoped>
.app-container {
  min-height: 600px;
}

.bg-dark .app-container {
  background-color: #121212;
  color: #ffffff;

}

.light-theme .app-container {
  background-color: #ffffff;
  color: #000000;
}

.app-container {
  display: flex;
  height: 100%;
}

.v-card-custom {
  flex: 1; /* Que ocupe todo el espacio disponible verticalmente */
  display: flex;
}


.v-navigation-drawer {
  height: 100%; /* Asegurar que el menú lateral ocupe toda la altura */
  overflow-y: auto; /* Permitir desplazamiento vertical si es necesario */
}

.v-main-custom {
  flex: 1; /* Que ocupe todo el espacio disponible verticalmente */
  overflow-y: auto; /* Permitir desplazamiento vertical si es necesario */
}

.v-list-item.v-theme--light.v-list-item--density-default.v-list-item--variant-text {
  margin-top: 12px;
  padding-right: 1px;
  margin-left: -5px;
}

.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.profile-image-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; /* No permitir que se reduzca */
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.username {
  font-size: 16px;
  margin-left: 15px;
  overflow: hidden; /* Oculta cualquier texto que se desborde */
  text-overflow: ellipsis; /* Muestra "@." cuando el texto es demasiado largo */
}
.toggle-text {
  margin-left: 8px;
  font-weight: bold; /* Negrita para mejor legibilidad */
  font-size: 18px; /* Ajusta el tamaño de fuente según tus necesidades */
  color: #007bff; /* Color de texto (puedes usar el color que desees) */
  text-transform: uppercase; /* Convierte el texto a mayúsculas si es necesario */
  transition: color 0.3s; /* Efecto de transición suave al cambiar color (opcional) */
}

.toggle-text:hover {
  color: #0056b3; /* Color del texto cuando el ratón está sobre él (opcional) */
}
</style>
