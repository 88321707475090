<template>
    <div class="container-xxl py-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <!-- <p>Industria ID: {{ id }}</p> -->
            <p v-if="isLoading">Cargando...</p>
          </div>
  
          <div v-if="usuariosPorTag && usuariosPorTag.length > 0" class="col-md-12">
            <h3>Usuarios que comparten la misma industria: {{ id }}</h3>
            <div class="row">
              <div
                v-for="usuario in usuariosPorTag"
                :key="usuario.ID"
                class="col-md-4 d-flex flex-column align-items-center"
              >
                <div class="middle-column p-3 rounded-3" :class="themeClass">
                  <!-- Enlace al perfil del usuario -->
                  <a :href="`#/perfil/${usuario.ID}`">
                    <!-- Imagen del perfil -->
                    <img
                      class="rounded-circle flex-shrink-0"
                       :src="usuario.profile_image_thumbnail_url || 'https://sinapsismentoring.com/wp-content/uploads/2024/Sinapsis-avatar.png'"
                      alt=""
                      style="width: 250px; height: 250px;"
                    />
                  </a>
                  <div class="ms-3" style="text-align: center;">
                    <h6 class="text-primary mb-1">
                      {{ usuario.user_first_name || usuario.display_name || 'Nombre no disponible' }}
                    </h6>
                    <small>Mentor</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  
    
    <script setup>
    import { ref, onMounted, watch, getCurrentInstance, inject,computed, reactive } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import ShareButton from '@/components/shareButton.vue';
  
    const store = useStore();
    const userId = ref(null);
     const profileImage = ref('');
     const userNickname = ref('');
    const userPosts = ref([]);
    const userFirstName = ref('');
    const userLastName = ref('');
    const userLocation = ref('');
    const userLanguages = ref('');
    const userTags = ref([]);
    const userDescription = ref('');
    const valuesArray= ref([]);
    const msjIdEnviador = ref(27);
    const msjIdRecibidor = ref(1);
    const codigoUnico = ref(null)
    const msjTitle = ref('');
    const msjContent = ref('');
    const errors = reactive({msjTitle: '',msjContent: ''});
    const errorMsj = ref('');
    const eserror = ref(false);
    const user = computed(() => store.getters.id);
          
     const isLoading = ref(false); // Define isLoading como una propiedad del componente
    const { proxy } = getCurrentInstance(); // Proxy para dominio
    const route = useRoute(); // Obtenemos la ruta actual
    const router = useRouter(); // Usamos useRouter para acceder al enrutador
    const id = route.query.id;
    const themeState = inject("theme");
    const usuariosPorTag = ref([]); // Inicializa usuariosPorTag como un array vacío

  
    //const profileImage = computed(() => store.getters.userProfileImage);
    const shareUrl = `${window.location.origin}${route.fullPath}`;
    //funcion para volver hacia atras 
   const goBack = () => {
    router.go(-1); // Vuelve a la página anterior
  };
      const themeClass = computed(() => {
        return themeState.theme === "dark"
          ? "bg-dark rounded"
          : "bg-light rounded";
      });
   
  
      //comienzo prueba
      const obtenerUsuariosPorTag = async (id) => {
  isLoading.value = true;

  try {
    const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/users-by-tag/?id=${id}`);
    if (!response.ok) {
      throw new Error('Error al obtener los usuarios por tag');
    }

    const usersData = await response.json();
    usuariosPorTag.value = usersData || []; // Asegúrate de que siempre sea un array

    // console.log('Usuarios por tag:', usersData);
  } catch (error) {
    console.error('Error al obtener los usuarios por tag:', error);
    usuariosPorTag.value = []; // Asigna un array vacío en caso de error
  } finally {
    isLoading.value = false;
  }
};

  
  
  
    
  
onMounted(() => {
  const id = route.query.id;
  
  // Reemplaza los guiones por espacios para que coincida con los tags en la base de datos
  const formattedId = id.replace(/-/g, ' ');

  // Llama a obtenerUsuariosPorTag con el id formateado
  obtenerUsuariosPorTag(formattedId);
});
  
  
    watch(() => route.params.id, () => {
      userId.value = route.params.id; // Actualizamos userId cuando cambian los parámetros de la ruta
      obtenerUsuario();
    });
  </script>
  
  <style>
  .MessageBoxS {
      
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #f9f9f9;
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      z-index: 9999;
  }
  .MessageBoxS h2 {
      margin-top: 0;
  }
  
  
  /* .col-md-4 .bg-light {
    background-color: rgba(255, 255, 255, 0.9) !important;
  } */
  
  </style>
  
  
    