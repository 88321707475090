import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "@mdi/font/css/materialdesignicons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import store from './store';
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App);

loadFonts();

//CONFIGURACION GLOBAL
app.config.globalProperties.$dominio = "https://sinapsismentoring.com";
//app.config.globalProperties.$dominio = "http://localhost";
app.config.globalProperties.$themeGlobal = "Hola mundo";


app.use(vue3GoogleLogin, {
    clientId: '848543581951-ikokfui1qsn31jp6ifq8sllpc25vua6s.apps.googleusercontent.com'
  })
app.use(router).use(store).use(vuetify).mount("#app");
