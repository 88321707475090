<template>
  <div class="custom-avatar-container">
    <div class="custom-avatar-header" v-if="imageCharged">
      <label for="custom-avatar-file-input" class="custom-avatar-file-label">Seleccionar imagen</label>
      <input id="custom-avatar-file-input" type="file" class="custom-avatar-file-input" @change="onFileChange" />
    </div>
    <div v-if="image" class="custom-avatar-cropper-container">
      <vue-cropper
        ref="cropper"
        :src="image"
        :aspect-ratio="1"
        :zoomable="false"
        :rotatable="false"
        :scalable="false"
        :view-mode="1"
        :autoCropArea="0.8"
        :movable="true"
        :dragMode="'move'"
        :cropBoxResizable="false"
        :cropBoxDraggable="true"
        class="custom-avatar-cropper"
        @ready="onCropperReady"
      />
    </div>
    
    <div class="custom-avatar-buttons" v-if="image">
      <v-alert
          v-if="eserror"
          density="compact"
          :text="errorMsg"
          title="Error"
          type="warning"
        ></v-alert>
      <v-btn :loading="loading" @click="resizeImage" >Listo</v-btn>
      <v-btn :loading="loading" @click="applyFilters" >Filtro</v-btn>
      <v-btn :loading="loading" @click="closeModal">Cancelar</v-btn>
       <!-- <button @click="applyFilters">Aplicar Filtros</button>
      <button @click="addFrame">Agregar Marco</button>
      <button @click="uploadImage">Subir Imagen</button> -->
    </div>
  </div>
</template>
  
  <script setup>
  import { ref, getCurrentInstance } from 'vue';
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  import Cookies from "js-cookie";
  import { useStore } from 'vuex';
  
  
  const image = ref(null);
  const imageCharged = ref(true);
  const croppedImage = ref(null);
  const cropper = ref(null);
  const store = useStore();
  const profileImageUrl = ref(null);
  const loading = ref(false);
  const eserror = ref(false);
  const errorMsg = ref("");
  const { proxy } = getCurrentInstance();
  
  const emit = defineEmits(["close-modal"]);
  
  const closeModal = () => {
      emit('close-modal');  // Emitir el evento para cerrar el modal
    };

  const onFileChange = (event) => {
    imageCharged.value = false;   
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        image.value = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  
  const onCropperReady = () => {
    const cropperInstance = cropper.value.cropper;
    cropperInstance.setCropBoxData({
      width: 400,
      height: 400,
      left: (cropperInstance.getContainerData().width - 400) / 2,
      top: (cropperInstance.getContainerData().height - 400) / 2,
    });
  };
  
  const resizeImage = () => {
   loading.value = true;
    if (cropper.value) {
      const canvas = cropper.value.cropper.getCroppedCanvas({ width: 500, height: 500 });
      croppedImage.value = canvas.toDataURL();
      uploadImage();
    }
  };
  
  const cropBoxSize = 400; // Tamaño del cropper en píxeles

  const applyFilters2 = async () => {
  console.log('Aplicando filtros a la imagen completa');
  
  if (image.value) {
    // Crear un nuevo canvas con el tamaño de la imagen original
    const img = new Image();
    img.src = image.value;
    
    img.onload = () => {
      const originalCanvas = document.createElement('canvas');
      const originalCtx = originalCanvas.getContext('2d');
      
      // Establecer el tamaño del canvas al tamaño de la imagen
      originalCanvas.width = img.width;
      originalCanvas.height = img.height;
      
      // Dibujar la imagen completa en el canvas
      originalCtx.drawImage(img, 0, 0);
      
      // Aplicar filtros CSS al contexto del canvas
      originalCtx.filter = 'grayscale(100%) sepia(100%) brightness(80%)';
      originalCtx.drawImage(img, 0, 0);
      
      // Convertir el canvas filtrado a dataURL
      const filteredImageDataURL = originalCanvas.toDataURL();
      
      // Actualizar la imagen mostrada
      image.value = filteredImageDataURL;

      // Forzar actualización del cropper
     // nextTick().then(() => {
     //   if (cropper.value) {
          cropper.value.cropper.replace(filteredImageDataURL);
        }
     // });
  //  };
  } else {
    console.error('No se ha cargado ninguna imagen');
  }
};


// funcion para enviar la imagen a la api
const applyFilters = async () => {
  console.log('Enviando imagen para eliminar el fondo');

  if (image.value) {
    try {
      const img = new Image();
      img.src = image.value;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        // Obtener la imagen en formato base64
        const imageDataURL = canvas.toDataURL('image/png');
        const base64Image = imageDataURL.split(',')[1]; // Obtener solo la parte base64

        // Enviar la imagen al servidor
        const response = await fetch('https://eldirectorweb-prueba.hf.space/api/predict/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: [base64Image] }), // Enviar como lista
        });

        if (response.ok) {
          const result = await response.json();
          const processedImageDataURL = `data:image/png;base64,${result.image}`;
          
          // Actualizar la imagen mostrada
          image.value = processedImageDataURL;

          // Forzar actualización del cropper
          // nextTick().then(() => {
          //   if (cropper.value) {
          //     cropper.value.cropper.replace(processedImageDataURL);
          //   }
          // });
        } else {
          console.error('Error al procesar la imagen');
        }
      };
    } catch (error) {
      console.error('Error al enviar la imagen:', error);
    }
  } else {
    console.error('No se ha cargado ninguna imagen');
  }
};


//fin de funcion




  
  const addFrame = () => {
    if (croppedImage.value) {
      const img = new Image();
      img.src = croppedImage.value;
      img.onload = () => {
        // Crear un canvas de 300x300 píxeles
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const diameter = 350;
        const radius = diameter / 2;
  
        canvas.width = diameter;
        canvas.height = diameter;
  
        // Dibujar un círculo blanco de 300x300 píxeles
        ctx.fillStyle = 'white';
        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, Math.PI * 2, false);
        ctx.closePath();
        ctx.fill();
  
        // Dibujar la imagen recortada dentro del círculo
        ctx.save();
        ctx.beginPath();
        ctx.arc(radius, radius, radius, 0, Math.PI * 2, false);
        ctx.clip();
        ctx.drawImage(img, (diameter - img.width) / 2, (diameter - img.height) / 2);
        ctx.restore();
  
        croppedImage.value = canvas.toDataURL();
      };
    }
  };
  
  const uploadImage = async () => {
  console.log('enviando');
  if (croppedImage.value) {
    
    try {
      // Convertir data URL a Blob si es necesario
      let imageBlob;
      if (typeof croppedImage.value === 'string' && croppedImage.value.startsWith('data:')) {
        imageBlob = dataURLtoBlob(croppedImage.value);
      } else if (croppedImage.value instanceof Blob) {
        imageBlob = croppedImage.value;
      } else {
        throw new Error('Tipo de imagen no soportado');
      }

      const formData = new FormData();
      formData.append('file', imageBlob, 'profile-image.jpg');

      // Obtener el token de la cookie
      const token = Cookies.get('token');

      const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/upload_profile_image`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      if (!response.ok) {
        eserror.value = true;
        errorMsg.value = 'Ocurrió un error inesperado, lo sentimos';
        loading.value = false;
        throw new Error('Error al subir la imagen');
      }
      const responseData = await response.json();
      console.log(responseData);
      profileImageUrl.value = responseData.profile_image_url;
      store.commit('updateProfileImage', profileImageUrl.value);
      console.log('Imagen subida correctamente');
      emit('close-modal');
    } catch (error) {
      loading.value = false;
      errorMsg.value = 'Ocurrió un error inesperado, lo sentimos';
      console.error(error);
    }
  }
};


// Función para convertir data URL a Blob
function dataURLtoBlob(dataURL) {
  const [header, data] = dataURL.split(',');
  const mime = header.match(/:(.*?);/)[1];
  const binary = atob(data);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: mime });
}
  </script>

<style>
.custom-avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: fit-content;
  margin: auto;
}

.custom-avatar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-avatar-file-label {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
}

.custom-avatar-file-input {
  display: none;
}

.custom-avatar-close-button {
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
}

.custom-avatar-cropper {
  width: 500px;
  height: 500px;
  border-radius: 50%;
}

.custom-avatar-cropper-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 500px;
  height: 500px;
  margin-right: 20px;
}

.custom-avatar-cropper .cropper-crop-box,
.custom-avatar-cropper .cropper-crop-box .cropper-face {
  border-radius: 50%;
  border: 2px solid #fff;
 }

.custom-avatar-cropper .cropper-view-box {
  border-radius: 50%;
  outline: 1px solid #fff;
}

.custom-avatar-cropper .cropper-drag-box {
  background-color: transparent !important;
}

.custom-avatar-buttons {
  display: inline;
  
  align-items: flex-start;
  margin-left: 20px;
}

.custom-avatar-buttons button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
}

.custom-avatar-buttons button:hover {
  background-color: #218838;
}
</style>