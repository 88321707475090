<template>
  <div class="container-xxl p-0">
  <v-carousel>
    <v-carousel-item cover>
      <picture>
        <source srcset="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/carousel-1-large.webp" media="(min-width: 1200px)">
        <source srcset="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/carousel-1-medium.webp" media="(min-width: 480px)">
        <img src="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/carousel-1-small.webp" class="v-img__img v-img__img--cover" loading="lazy" width="600" height="250" alt="Sinapsis mentoring image index">
      </picture>
      <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style="background: rgba(0, 0, 0, .2);">
        <div class="container">
          <div class="row justify-content-start">
            <div class="col-11 col-lg-9">
              <h1 class="text-white mb-3 responsive-text responsive-title">Únete a nuestra red de mentores y forjemos el futuro juntos</h1>
              <p class="responsive-text mb-4 pb-10 text-white bg-dark-transparent">
                "La mentoria es una práctica poderosa que ha guiado a líderes y transformado sociedades. Al sumarte a nuestra red, contribuyes a forjar un futuro sólido y sostenible. Comparte tu experiencia, inspira a nuevas mentes y sé parte de una comunidad que marca la diferencia."
              </p>
              <a :href="'#/about/'" class="btn btn-primary rounded-pill mb-5 px-sm-5 me-6">Aprende más</a>
              <a :href="'#/registrarse'" class="btn btn-dark mb-5 rounded-pill px-sm-5">Regístrate</a>
            </div>
          </div>
        </div>
      </div>
    </v-carousel-item>

    <v-carousel-item cover>
      <picture>
        <source srcset="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/carousel-2-large.webp" media="(min-width: 1200px)">
        <source srcset="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/carousel-2-medium.webp" media="(min-width: 480px)">
        <img src="https://sinapsismentoring.com/wp-content/themes/template-de-prueba/img/carousel-2-small.webp" class="v-img__img v-img__img--cover" width="600" height="250" loading="lazy" alt="...">
      </picture>
      <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style="background: rgba(0, 0, 0, .2);">
        <div class="container">
          <div class="row justify-content-start">
            <div class="col-11 col-lg-9">
              <h1 class="text-white mb-3 responsive-text responsive-title">¿Buscas una mentoría que te ayude a crecer en todos los aspectos de tu vida?</h1>
              <p class="responsive-text mb-4 pb-10 text-white bg-dark-transparent">Impulsa tu crecimieto y desarrolla tus habilidades a través de nuestros Mentores. Nuestros programas ofrecen  un enfoque integral para ayudarte a alcanzar tus objetivos profesionales y personales. Transforma tu futuro con nuestro servicio. ¡Inscríbete hoy y comienza tu transformación!</p>
              <a :href="'#/about/'" class="btn btn-primary rounded-pill mb-5 px-sm-5 me-6">Aprende más</a>
              <a :href="'#/registrarse'" class="btn btn-dark mb-5 rounded-pill px-sm-5">Regístrate</a>
            </div>
          </div>
        </div>
      </div>
    </v-carousel-item>
  </v-carousel>
</div>

<!-- Muestro industrias mas populares  -->
<muestra-tags />

  <div class="container-xxl py-0">
    <div class="container mt-3">
      <div class="row align-items-center row-container">
        <!-- Columna Izquierda -->
        <div class="col-md-6 deg mt-md-0 mt-3">
          <div :class="themeClass">
            <div class="rounded p-3 d-flex justify-content-around">
              <button @click="showMentores" class="btn btn-primary">
                Para Mentores
              </button>
              <button @click="showEmprendedores" class="btn btn-primary">
                Para Emprendedores
              </button>
            </div>
          </div>
        </div>
        <!-- Columna Derecha -->
        <div class="col-md-6 mt-md-0 mt-3">
          <div :class="themeClass">
            <div class="rounded p-3 info-container">
              <ul>
                <li v-show="isMentorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >🚀¡Participa!</span
                  >
                  Regístrate en Sinapsis como mentor y enseña a los
                  emprendedores.
                </li>
                <li v-show="isMentorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >📖Capacitaciones</span
                  >
                  Accede a capacitaciones especiales de Sinapsis*.
                </li>
                <li v-show="isMentorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >💬Conecta</span
                  >
                  Conecta con los emprendedores, conviértete en su mentor,
                  enseña.
                </li>
                <li v-show="isMentorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >📈Mejora</span
                  >
                  Después de conectar con un emprendedor, es importante entablar
                  conversaciones significativas, compartir experiencias y
                  escuchar atentamente. A través de esta interacción, pueden
                  desarrollar una relación sólida y trabajar juntos para
                  impulsar el crecimiento de su negocio.
                </li>
                <li v-show="isEmprendedorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >🚀¡Participa!</span
                  >
                  Regístrate en Sinapsis para compartir quién eres y buscar
                  apoyo de los mentores.
                </li>
                <li v-show="isEmprendedorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >🌍Descubre</span
                  >
                  Busca y accede a los mejores mentores del mundo.
                </li>
                <li v-show="isEmprendedorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >💬Conecta</span
                  >
                  Conecta con los mentores, aprende y mejora tus habilidades
                  como emprendedor.
                </li>
                <li v-show="isEmprendedorActive">
                  <span style="font-size: larger; font-weight: bold"
                    >📈Mejora</span
                  >
                  Después de conectar con un mentor para ser tu guía, es crucial
                  expresar tus inquietudes, aprender de sus experiencias y
                  escuchar con atención. A través de esta colaboración, pueden
                  establecer una relación sólida y trabajar juntos para impulsar
                  el crecimiento de tu negocio.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-xxl py-0">
    <div class="container">
      <div
        class="text-center mx-auto mb-5 wow fadeInUp"
        data-wow-delay="0.1s"
        style="max-width: 600px"
      >
        <h1 class="mb-3" style="color: orange">Mejores mentores</h1>
      </div>
        <div class="row g-4">
    <!-- Mentor individual -->
    <div
      v-for="mentor in mentors.slice(0, 3)"
      :key="mentor.id || Math.random()"
      class="col-lg-4 col-md-6 wow fadeInUp"
      data-wow-delay="0.1s"
    >
      <div class="classes-item">
        <div :class="themeClass">
          <div class="rounded p-4 pt-1 mt-n5">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <div class="d-flex align-items-center">
                <a :href="'#/perfil/' + (mentor.id || '#')">
                  <img
                    v-if="mentor.profile_image"
                    class="rounded-circle flex-shrink-0 responsive-img"
                    :src="mentor.profile_image"
                    alt="Perfil del mentor"
                  />
                  <div v-else class="rounded-circle flex-shrink-0 responsive-img" style="background-color: #ddd; width: 60px; height: 60px;"></div>
                </a>
                <div class="ms-3">
                  <h6 class="textocolor mb-1" style="overflow-wrap: anywhere">
                    <strong>{{ mentor.first_name || 'Cargando...' }}</strong>
                  </h6>
                  <small class="text-grey">Mentor</small>
                </div>
              </div>
              <a :href="'#/perfil/' + (mentor.id || '#')">
                <span class="bg-blue text-white rounded-pill py-2 px-3">Info</span>
              </a>
            </div>
            <div class="row g-1">
              <div class="col-4">
                <div class="border-top border-3 border-primary pt-2">
                  <h6 class="text-primary mb-1">Idioma:</h6>
                  <small>{{ mentor.idioma || '...' }}</small>
                </div>
              </div>
              <div class="col-4">
                <div class="border-top border-3 border-success pt-2">
                  <h6 class="text-success mb-1">Mentorias</h6>
                  <small>{{ mentor.mentor_count || '...' }}</small>
                </div>
              </div>
              <div class="col-4">
                <div class="border-top border-3 border-warning pt-2">
                  <h6 class="text-warning mb-1">Puntuacion</h6>
                  <small>4 ****</small>
                </div>
              </div>
            </div>
            <div class="border border-success rounded pt-2" style="background-color: rgba(200, 200, 200, 0.5)">
              <p style="font-size: 1.2em; font-weight: bold; margin-bottom: 0; margin-left: 10px;">
                Industria:
              </p>
              <p class="ml-1" v-for="tag in (mentor.user_tags || []).slice(0, 5)" :key="tag.value">
                {{ tag.value }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mentor individual -->
  </div>
    </div>
  </div>

  <div class="container mt-3">
    <div class="row">
      <div class="col-md-3">
        <div class="estadisticas"
        :class="themeClass"
         >
          <h1 class="estadisticas__estad_text">10</h1>
          <div class="estadisticas__text">
            <p>Horas el emprendedor recibe en promedio de asesoramiento.</p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="estadisticas"
        :class="themeClass">
          <h1 class="estadisticas__estad_text">85%</h1>
          <div class="estadisticas__text">
            <p>
              De los nuevos negocios creados por emprendedores que recibieron
              mentoría no fracasan.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="estadisticas"
        :class="themeClass">
          <h1 class="estadisticas__estad_text">2×</h1>
          <div class="estadisticas__text">
            <p>
              Los emprendedores que recibieron mentoría generaron el doble de
              empleos en comparación con aquellos que no la recibieron
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="estadisticas"
        :class="themeClass">
          <h1 class="estadisticas__estad_text">55%</h1>
          <div class="estadisticas__text">
            <p>
              De los emprendedores no tienen acceso a ningún otro tipo de apoyo
              para sus negocios aparte de Sinapsis.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin Menu Estadisticas -->

  <!-- <v-main>
    <v-container fluid>
      <v-row dense>
        <v-col
          v-for="(item, index) in entradas"
          :key="index"
          cols="12"
        >
          <v-card
            :subtitle="`${item.id}`"
            :title="`${item.title}`"
            text=""
          ></v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main> -->
</template>

<script>
import { ref, onMounted, getCurrentInstance, computed, inject } from "vue";
import  muestraTags  from "@/components/muestraTags.vue";

export default {
  components: {
    muestraTags, // Registra el componente para que esté disponible en el template
  },
  
  setup() {
    const entradas = ref([]);
    const mentors = ref(Array(3).fill({
  user_tags: Array(5).fill().map((_, index) => ({ value: `Palabra ${index + 1}` }))
}));
    const isMentorActive = ref(true);
    const isEmprendedorActive = ref(false);
    const { proxy } = getCurrentInstance();

    const themeState = inject("theme");

    const themeClass = computed(() => {
      return themeState.theme === "dark"
        ? "bg-dark rounded"
        : "bg-light rounded";
    });

    const filteredMentors = computed(() => {
  return mentors.value.filter(mentor => mentor.profile_image);
});

    const getEntradas = async () => {
      try {
        const postsResponse = await fetch(
          `${proxy.$dominio}/wp-json/wp/v2/posts`,
        );
        const postsData = await postsResponse.json();

        postsData.forEach((entry) => {
          if (entry.title && entry.title.rendered) {
            entradas.value.push({
              id: entry.id,
              title: entry.title.rendered,
            });
          }
        });
      } catch (error) {
        console.error(error);
      }
    };

    const getLatestUsers = async () => {
      try {
        const response = await fetch(
          `${proxy.$dominio}/wp-json/custom/v2/latest-users`,
        );
        const data = await response.json();
        mentors.value = data;
        // console.log(mentors);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
            getEntradas();
      getLatestUsers();
    });

   

    const showMentores = () => {
      isMentorActive.value = true;
      isEmprendedorActive.value = false;
    };

    const showEmprendedores = () => {
      isMentorActive.value = false;
      isEmprendedorActive.value = true;
    };

    return {
      entradas,
      mentors,
      isMentorActive,
      isEmprendedorActive,
      showMentores,
      showEmprendedores,
      themeClass,
      filteredMentors,
    };
  },
};
</script>
<style>

.bg-dark.rounded.estadisticas  {
  background: linear-gradient(rgb(0, 123, 255), rgb(18, 18, 18));
}
.bg-light.rounded.estadisticas  {
  background: linear-gradient(rgb(0, 123, 255), rgb(0, 230, 230));
}
.bg-dark .textocolor {
  color:rgb(216, 218, 219);
}
.estadisticas {
  
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;
}
.estadisticas__estad_text {
  font-size: 2.5rem;
  font-weight: bold;
  border: 4px solid orange; /* Añade un borde naranja */
  padding: 10px; /* Ajusta el relleno para que el texto no esté pegado al borde */
  border-radius: 40px; /* Opcional: para redondear los bordes del recuadro */
}
.estadisticas__text {
  font-size: 1rem;
}
.v-carousel__controls__prev,
.v-carousel__controls__next {
  color: black !important; /* Ensure the controls are visible */
}



.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.3);
}

.bg-dark .rounded {
  background: linear-gradient(rgb(39, 33, 33), rgb(18, 18, 18));
}
.bg-light .rounded {
  background: linear-gradient(rgb(240 240, 240), rgb(247, 247, 247));
  }
 .mdi-chevron-right {
  color: black;
 }
 .mdi-chevron-left {
  color: black;
 }
 /* Tamaño base para el texto */
 /* Tamaño base para el texto */
.responsive-text {
  font-size: 1rem; /* Tamaño base */
  padding: 0 1rem; /* Ajuste de espaciado */
  margin-left: 2.5rem; /* Asegurarse de que no haya margen izquierdo predeterminado */
}

/* Ajuste específico para el título (h1) */
.responsive-title {
  font-size: 1.5rem; /* Tamaño de fuente más grande para el título por defecto */
}

/* Ajuste para pantallas pequeñas */
@media (max-width: 576px) {
  .responsive-text {
    font-size: 1rem; /* Tamaño de fuente más pequeño para pantallas pequeñas */
  }
  .responsive-title {
    font-size: 1.4rem; /* Tamaño de fuente más pequeño para el título en móviles */
  }
}

/* Ajuste para pantallas medianas */
@media (min-width: 768px) and (max-width: 991px) {
  .responsive-text {
    font-size: 1rem; /* Tamaño de fuente normal para pantallas medianas */
  }
  .responsive-title {
    font-size: 1.75rem; /* Tamaño de fuente más grande para el título en pantallas medianas */
  }
}

/* Ajuste para pantallas grandes */
@media (min-width: 992px) {
  .responsive-text {
    font-size: 1rem; /* Tamaño de fuente normal para pantallas grandes */
  }
  .responsive-title {
    font-size: 2.5rem; /* Tamaño de fuente más grande para el título en pantallas grandes */
    margin-left: 3.5rem; /* Ajustar el margen izquierdo si es necesario */
  }
}
/* CSS para ajustar el tamaño de la imagen según el dispositivo */
.responsive-img {
  width: 90px; /* Tamaño predeterminado */
  height: 90px; /* Tamaño predeterminado */
}

/* Ajustes para pantallas móviles */
@media (max-width: 600px) {
  .responsive-img {
    width: 70px; /* Tamaño más pequeño para móviles */
    height: 70px; /* Tamaño más pequeño para móviles */
  }
}

/* Ajustes para pantallas de escritorio (opcional) */
@media (min-width: 601px) {
  .responsive-img {
    width: 90px; /* Tamaño predeterminado para pantallas grandes */
    height: 90px; /* Tamaño predeterminado para pantallas grandes */
  }
}


</style>