<template>
  <v-container v-if="loadingComponent" class="text-center">
    <v-btn color="primary" disabled>Cargando...</v-btn>
  </v-container>
  <v-data-table
    v-else
    :headers="headers"
    :items="entradas"
    :sort-by="[{ key: 'id', order: 'asc' }]"
    :footer-props="{
    'items-per-page-text': 'Elementos por página'
  }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Mis Mentorias</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ props }">
            <v-btn class="mb-2" color="primary" dark v-bind="props">
              
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field
                      v-model="editedItem.title"
                      label="Dessert title"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field
                      v-model="editedItem.id"
                      label="id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field
                      v-model="editedItem.content"
                      label="content (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field
                      v-model="editedItem.date"
                      label="date (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" sm="6">
                    <v-text-field
                      v-model="editedItem.status"
                      label="status (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Esta seguro que desea borrar esta mentoria?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <button type="button" class="btn btn-primary ml-1" @click="openModal(item.id)">
    <v-icon size="small">mdi-pencil</v-icon>
  </button>
    <button type="button" class="btn btn-primary ml-1">  
      <v-icon size="small" @click="deleteItem(item)">mdi-delete</v-icon>
    </button>
    </template>
  </v-data-table>
  <!-- Empiezo codigo para mostrar el modal -->
    <div v-if="showModal" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-black">Editar</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <!-- Aquí se usa el componente Editar con el postId dinámico -->
          <Editar :post-id="postId" @close-modal="closeModal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Cookies from "js-cookie";
import { useRouter } from "vue-router";
import Editar from "../components/panelPerfil/editar.vue";

export default {
  components: {
    Editar
  },
  data: () => ({
    postId: null,
    dialog: false,
    dialogDelete: false,
    loadingComponent: ref(true),
    showModal: ref(false),
        headers: [
      {
        title: "Titulo",
        align: "start",
        sortable: false,
        key: "title",
      },
      { title: "id", key: "id" },
      { title: "Contenido", key: "content" },
      { title: "Fecha", key: "date" },
      { title: "Status", key: "status" },
      { title: "Acciones", key: "actions", sortable: false },
    ],
    entradas: [],
    editedIndex: -1,
    editedItem: {
      title: "",
      id: 0,
      content: 0,
      date: 0,
      status: 0,
    },
    defaultItem: {
      title: "",
      id: 0,
      content: 0,
      date: 0,
      status: 0,
    },
  }),


  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
     modalSize() {
  return window.innerWidth < 768 ? 'modal-sm' : 'modal-lg';
  }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {

    openModal(id) {
      this.postId = String(id);
      console.log(this.postId);  // Asignamos dinámicamente el id del post
      this.showModal = true;
    },

    closeModal() {
    this.showModal = false;
    this.entradas = [];
    this.refreshComponentData();
    this.postId = null; // Reiniciamos postId al cerrar el modal
  },
  refreshComponentData() {
    // Aquí puedes volver a cargar datos o actualizar el estado.
    this.initialize();
  },

    async initialize() {
      const router = useRouter();
      try {
        // Obtener el token de autenticación del almacenamiento local (o de donde lo estés almacenando)
        const token = Cookies.get("token"); // Reemplaza 'token' con el nombre de tu clave de token

        if (!token) {
          alert("Cerrada la sesión, vuelve a iniciarla.");
          router.push("/login");
          return;
        }

        // Hacer la solicitud a la API de WordPress solo para los posts del usuario autenticado

        let userId = null;

        // Función para obtener el ID del usuario actual

        try {
          const response = await fetch(
            this.$dominio + "/wp-json/wp/v2/users/me",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          );

          if (response.ok) {
            const userData = await response.json();
            userId = userData.id; // Obtenemos el ID del usuario
            // Llamamos a la función para obtener las publicaciones
          } else {
            console.error(
              "Error al obtener los datos del usuario:",
              response.statusText,
            );
            alert("Cerrada la sesión, vuelve a iniciarla.");
            router.push("/login");
            // Maneja el error según tus necesidades
          }
        } catch (error) {
          console.error(
            "Error de red al obtener los datos del usuario:",
            error,
          );
          alert("Cerrada la sesión, vuelve a iniciarla.");
          router.push("/login");
          // Maneja el error de red según tus necesidades
        }

        const response = await fetch(
          this.$dominio + "/wp-json/wp/v2/posts?author=" + userId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.ok) {
          const posts = await response.json();
          if (posts.length === 0) {
    alert('No tienes mentorias.');
    this.loadingComponent = false;
  } else {
          posts.forEach((element) => {
            let item = {
              id: element.id,
              date: element.date,
              status: element.status,
              content: this.limpiar(element.content.rendered),
              title: element.title.rendered,
            };
            this.loadingComponent = false;
            this.entradas.push(item);
          });
        }
       } else {
          console.error("Error al obtener los posts del usuario");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    // Para limpiar <p> del content
      limpiar(value, maxLength = 100) {
  // Eliminar etiquetas HTML
  const cleanValue = value.replace(/<\/?[^>]+(>|$)/g, "");

  // Cortar a la longitud deseada y agregar "..."
  if (cleanValue.length > maxLength) {
    return cleanValue.substring(0, maxLength) + "...";
  }

  return cleanValue;
},

    editItem(item) {
      this.editedIndex = this.entradas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.entradas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      console.log("Eliminado");
    },

    async deleteItemConfirm() {
      const deletepost = this.entradas[this.editedIndex].id;
      const token = Cookies.get("token");
      const respuesta = await fetch(
        this.$dominio + "/wp-json/wp/v2/posts/" + deletepost,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      this.entradas.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
       closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const userID = Cookies.get("id");
      const token = Cookies.get("token");

      if (this.editedIndex > -1) {
        // Editar
        const id_post = this.entradas[this.editedIndex].id;
        Object.assign(this.entradas[this.editedIndex], this.editedItem);
        const entrada = {
          title: this.editedItem.title,
          content: this.editedItem.content,
        };
        try {
          const respuesta = await fetch(
            this.$dominio + "/wp-json/wp/v2/posts/" + id_post,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(entrada),
            },
          );
        } catch (error) {
          console.error("Error de red:", error);
          // Manejar el error de red apropiadamente
        }
      } else {
        // Agregar
        console.log(this.editedItem);
        const entrada = {
          title: this.editedItem.title,
          content: this.editedItem.content,
          status: "publish",
        };
        try {
          const respuesta = await fetch(
            this.$dominio + "/wp-json/wp/v2/posts/",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(entrada),
            },
          );

          if (respuesta.ok) {
            const entradaNueva = await respuesta.json();
            console.log(entradaNueva);
            this.editedItem.id = entradaNueva.id;
            this.editedItem.date = entradaNueva.date;
            this.editedItem.status = entradaNueva.status;
          } else {
            console.error("Error al crear el post:", respuesta.status);
            // Manejar el error apropiadamente, dependiendo de tus requerimientos
          }
        } catch (error) {
          console.error("Error de red:", error);
          // Manejar el error de red apropiadamente
        }

        this.entradas.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

  <style scoped>
.modal.fade.show {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;  
  width: 90%;
  max-height: 80vh;
  max-width: 600px;
}

.modal-dialog.modal-lg {
  max-width: 600px;
}
.modal-dialog.modal-sm {
  max-width: 90%;
}
.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
</style>
