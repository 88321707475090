<template>
  <v-app :class="themeClass">
    <PageHeader :themeGlobal="themeClass" />

    <v-main class="main-content" :style="{ height: loading ? '1000px' : 'auto' }">
      <!-- Loader mientras el contenido está cargando -->
      <div v-if="loading" class="loader">
        <v-progress-circular
          indeterminate
          color="primary"
          size="70"
        ></v-progress-circular>
      </div>

      <!-- Cuando los datos se carguen, mostrar el router-view -->
      <div v-else>
        <router-view />
      </div>

      <!-- Footer -->
      <pageFooter />
    </v-main>
  </v-app>
</template>

<script>
import { ref, onMounted, reactive, provide, computed, watch } from "vue";
import PageHeader from "./components/PageHeader.vue";
import pageFooter from "./components/pageFooter.vue";

export default {
  name: "App",
  components: {
    PageHeader,
    pageFooter,
  },
  setup() {
    const state = reactive({
      theme: "light",
      loading: true, // Estado de carga
    });

    // Función para detectar y configurar el tema
    const detectAndSetTheme = () => {
      state.theme = window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";
    };

    onMounted(() => {
      detectAndSetTheme();
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", detectAndSetTheme);

      // Simular un retraso en la carga (como una llamada a una API)
      setTimeout(() => {
        state.loading = false; // Detener el loader después de la carga
      }, 2000); // Simulación de 2 segundos de carga
    });

    // Proveer el tema globalmente usando provide
    provide("theme", state);

    const themeClass = computed(() => {
      return state.theme === "dark" ? "dark-theme" : "light-theme";
    });

    const toggleTheme = () => {
      state.theme = state.theme === "dark" ? "light" : "dark";
    };

    return {
      themeClass,
      toggleTheme,
      state,
      loading: computed(() => state.loading), // Computed para el estado de carga
    };
  },
};
</script>

<style>
/* Define estilos para los temas */
.dark-theme {
  background-color: #121212;
  color: #ffffff;
}

.light-theme {
  background-color: #ffffff;
  color: #000000;
}

.theme-toggle-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
body,
.main-content {
  padding-top: 39px; /* Ajusta este valor según la altura de tu navbar */
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
