<template>
    <div v-if="showModal3" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" :class="modalSize">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-black">Convertir texto a voz</h5>
            <button type="button" class="btn-close" @click="closeModal3"></button>
          </div>
          <div class="modal-body">
            <div class="translation-section">
              <label for="text-input" class="form-label">Ingresa el texto en español:</label>
              <textarea
                id="text-input"
                v-model="inputText"
                class="form-control"
                rows="4"
                placeholder="Escribe el texto que deseas convertir a voz."
              ></textarea>
  
              <label for="voice-selection" class="form-label mt-3">Selecciona la voz:</label>
              <select v-model="selectedVoice" id="voice-selection" class="form-select">
                <option value="es-AR-ElenaNeural">Elena (Femenina)</option>
                <option value="es-AR-TomasNeural">Tomás (Masculina)</option>
              </select>
  
              <v-btn 
                @click="convertirTextoAVoz" 
                color="primary" 
                :loading="isLoading" 
                :disabled="isLoading || !inputText"
                class="mt-3"
              >
                Convertir Texto a Voz
              </v-btn>
  
              <v-divider></v-divider>
  
              <div v-if="isLoading" class="loading-message">
                Convirtiendo el texto, por favor espera...
              </div>
  
              <audio v-if="audioUrl" :src="audioUrl" controls class="mt-3"></audio>
            </div>
          </div>
        </div>
      </div>
      <div class="" @click="closeModal3"></div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  // Estado del componente
  const inputText = ref('');         // Texto ingresado por el usuario
  const selectedVoice = ref('es-AR-ElenaNeural'); // Voz seleccionada (por defecto Elena)
  const audioUrl = ref(null);        // URL del archivo de audio generado
  const isLoading = ref(false);      // Estado de carga
  const apiUrl = 'https://sinapsisspace-text-to-voice.hf.space/text-to-speech'; // Cambiar por la URL de tu API TTS
  
  // Props y emisión de eventos
  const props = defineProps({
    showModal3: Boolean,
    modalSize: {
      type: String,
      default: ''
    }
  });
  const emit = defineEmits(['update:showModal3']);
  
  const closeModal3 = () => {
    emit('update:showModal3', false);
    inputText.value = '';
    audioUrl.value = null; // Limpiar el audio cuando se cierre el modal
  };
  
  // Función para convertir texto a voz
  async function convertirTextoAVoz() {
    if (!inputText.value) return;
  
    isLoading.value = true;
    audioUrl.value = null;  // Limpiar el audio anterior
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: inputText.value,
          voice: selectedVoice.value,
        }),
      });
  
      if (response.ok) {
        const audioBlob = await response.blob();
        audioUrl.value = URL.createObjectURL(audioBlob); // Crear URL para reproducir el audio
      } else {
        console.error('Error al convertir el texto a voz:', await response.text());
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    } finally {
      isLoading.value = false;
    }
  }
  </script>
  
  
  <style scoped>
  .loading-message {
    color: grey;
    font-style: italic;
  }
  </style>