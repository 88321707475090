<template>
    <div :class="['tags-container', themeClass, 'position-relative', 'overflow-hidden', 'text-center', 'py-5']">
      <div class="moving-icons">
        <i class="fas fa-star moving-icon"></i>
        <i class="fas fa-heart moving-icon"></i>
        <i class="fas fa-leaf moving-icon"></i>
      </div>
      <div class="tags-content d-flex flex-column align-items-center justify-content-center h-100">
        <h2 class="mb-4" :class="textClass">Top 10 Industrias más populares</h2>
        <ul class="list-unstyled d-flex flex-wrap justify-content-center">
          <li v-for="(tag, index) in tags" :key="index" class="mx-2">
            <a :href="`#/industria/?id=${tag.slug}`" :class="['btn', 'animated-link', linkClass]">
              {{ tag.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </template>
  <script setup>
  import { ref, onMounted, inject, computed } from 'vue';
  
  const tags = ref([]);
  const themeState = inject("theme");
  
  const themeClass = computed(() => {
    return themeState.theme === "dark" ? "bg-dark" : "bg-light";
  });
  
  const textClass = computed(() => {
    return themeState.theme === "dark" ? "text-light" : "text-dark";
  });
  
  const linkClass = computed(() => {
    return themeState.theme === "dark" ? "btn-outline-light" : "btn-outline-dark";
  });
  
  onMounted(async () => {
    try {
      const response = await fetch('https://sinapsismentoring.com/wp-json/custom/v1/most-repeated-tags/');
      if (!response.ok) {
        throw new Error('Error al obtener los tags');
      }
      const data = await response.json();
      tags.value = data.map(item => ({
        name: item.tag,
        slug: item.tag.toLowerCase().replace(/\s+/g, '-')
      }));
    } catch (error) {
      console.error('Error al hacer fetch de los tags:', error);
    }
  });
  </script>
  
  
  <!-- onMounted(() => {
    // Simulando un fetch a una API con tags populares
    setTimeout(() => {
      tags.value = [
        { name: 'Vue 3', slug: 'vue-3' },
        { name: 'JavaScript', slug: 'javascript' },
        { name: 'CSS Animations', slug: 'css-animations' },
        { name: 'Web Design', slug: 'web-design' },
        { name: 'Responsive UI', slug: 'responsive-ui' },
      ];
    }, 500); // Simula un retraso en la llamada a la API
  }); -->
  <style scoped>
  .tags-container {
    background-size: cover;
    position: relative;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .bg-dark {
    background-color: #222;
  }
  
  .bg-light {
    background-color: #f8f9fa;
  }
  
  .text-light {
    color: white;
  }
  
  .text-dark {
    color: #212529;
  }
  
  .moving-icons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
  }
  
  .moving-icon {
    font-size: 4rem;
    position: absolute;
    animation: moveIcons 8s infinite alternate ease-in-out;
  }
  
  .moving-icon:nth-child(1) { top: 10%; left: 20%; animation-delay: 0s; }
  .moving-icon:nth-child(2) { top: 40%; left: 70%; animation-delay: 2s; }
  .moving-icon:nth-child(3) { top: 80%; left: 30%; animation-delay: 4s; }
  
  @keyframes moveIcons {
    from { transform: translate(0, 0) rotate(0deg); }
    to { transform: translate(20px, -20px) rotate(360deg); }
  }
  
  .tags-content {
    z-index: 1;
  }
  
  .animated-link {
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
  }
  
  .animated-link::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(120deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    transition: left 0.4s ease;
  }
  
  .animated-link:hover::before {
    left: 100%;
  }
  
  .animated-link:hover {
    color: #5a41ca;
  }
  </style>
  