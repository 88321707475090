<!-- Activar aca thumb - CUando hayamos creado las miniaturas de perfil -->
<template>
  <div>
    <form @submit.prevent="submitForm">
      <!-- Agrega el evento @submit.prevent para evitar el envío del formulario por defecto -->
      <v-img
        class="mx-auto my-6"
        max-width="300"
        :src="require('@/assets/logosinapsis.png')"
      ></v-img>

      <v-card
        class="mx-auto pa-12 pb-8"
        :class="{'dark-mode': themeClass === 'dark', 'light-mode': themeClass === 'light'}"
        elevation="8"
        max-width="448"
        rounded="lg"
      >
        <div class="text-subtitle-1 text-medium-emphasis"
        :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}">Cuenta</div>

        <v-text-field
          v-model="username"
          density="compact"
          placeholder="Direccion de correo"
          prepend-inner-icon="mdi-email-outline"
          variant="outlined"
          class="username"
          autocomplete="current-username"
        ></v-text-field>

        <div
          class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between"
          :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}"
        >
          Contraseña

          <a
            class="text-caption text-decoration-none text-blue"
            href="#/recoverpasswd"
            rel="noopener noreferrer"
            
          >
            Has olvidado tu usuario o contraseña?</a
          >
        </div>

        <v-text-field
          v-model="password"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Ingrese su contraseña"
          class="password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          autocomplete="current-password"
          @click:append-inner="visible = !visible"
        ></v-text-field>
        <v-card class="mb-12" color="surface-variant" variant="tonal">
          <v-card-text class="text-medium-emphasis text-caption"
          :class="{'text-light': themeClass === 'dark', 'text-dark': themeClass === 'light'}">
            Atencion: Después de 3 intentos fallidos, se bloqueará el acceso durante 3
            horas. Si deseas puedes resetear la contraseña desde el enlace mas arriba.
              </v-card-text>
        </v-card>

        <v-alert
          v-if="eserror"
          density="compact"
          :text="errorMsg"
          title="Error"
          type="warning"
        ></v-alert>

        <v-btn
          :loading="loading"
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          block
          type="submit"
        >
          Iniciar Sesión        </v-btn>
          <!-- <v-btn
          :loading="loading"
          class="mb-8"
          color="blue"
          size="large"
          variant="tonal"
          @click="logintest"
          block
          
        >
          Iniciar SesiónTrucha        </v-btn> -->

          <!-- <GoogleLogin :callback="callback" prompt auto-login/> -->
          <v-card-text class="text-center">
          <a
            class="text-blue text-decoration-none"
            href="#/registrarse"
            rel="noopener noreferrer"
            target="_blank"
          >
            Registrarse Ahora <v-icon color="blue" icon="mdi-chevron-right"></v-icon>
          </a>
        </v-card-text>
      </v-card>
    </form>
  </div>
  <div class="mb-4"></div>
</template>

<script>
import Cookies from "js-cookie";
import { ref, getCurrentInstance,inject,computed,watch } from "vue"; // Importa ref
import { useRouter } from "vue-router"; // Importa useRouter
import { useStore } from 'vuex';
import { decodeCredential } from 'vue3-google-login'

export default {
  setup() {
    const loading = ref(false);
    const visible = ref(false);
    const username = ref("");
    const password = ref("");
    const eserror = ref(false);
    const errorMsg = ref("");
    const token = ref("");
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter(); // Obtiene el enrutador
    const setUserName = ref("");
    
    const toggle = ref(false);
    const themeState = inject("theme");
    const themeClass = computed(() => {
      return themeState.theme === "dark" ? "dark" : "light";
    });
 
    watch(
      themeClass,
      (newClass) => {
        toggle.value = newClass === "light";
      },
      { immediate: true },
    );
    const onToggleChange = () => {
      themeState.theme = themeState.theme === "dark" ? "light" : "dark";
    };


    const callback = (response) => {
  // decodeCredential will retrive the JWT payload from the credential
  const userData = decodeCredential(response.credential)
  // console.log("Handle the userData", userData) ;
 loginGoogle(userData);
}

// async function logintest() {
//   try {
//     const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/authejemplo`, {
//       method: "GET",
//     });
//     const data = await response.json();
//     const user = {
//               name: data.user.display_name,
//               id: data.user.ID,
//               token: data.token,
//              //Activar aca thumb
//               //profileImage: userData.meta.profile_image_thumbnail, // Ajusta esto según la estructura de tu respuesta
//               profileImage: data.user.profile_picture,
//             };

//     console.log(token.value);
//     console.log(user);
//    Cookies.set("token", token.value);
//    store.commit('setAuthenticated', { user });
//      router.push("/PagePanel");
  
//   } catch (error) {
//     console.error("Error:", error);
//   }
// }

async function loginGoogle(userData) {
      try { 
        loading.value = true;
        const response = await fetch(
          `${proxy.$dominio}/wp-json/custom/v1/auth`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: userData.email,
              name: userData.name,
              picture: userData.picture,
            }),
          },
        );
        const data = await response.json();
        // console.log(data); // Aquí puedes manejar la respuesta de la API
         if (response.ok) {
          token.value  = data.token;
    const user = {
              name: data.user.display_name,
              id: data.user.ID,
              token: token.value,
             //Activar aca thumb
              //profileImage: userData.meta.profile_image_thumbnail, // Ajusta esto según la estructura de tu respuesta
              profileImage: data.user.profile_image,
            };
            // console.log(token.value);
    // console.log(user);
   
   store.commit('setAuthenticated', { user });
     router.push("/PagePanel");            
        }
      } catch (error) {
        console.log('error');

        }
      };

    async function submitForm() {
      // console.log("Username:", username.value);
      // console.log("Password:", password.value);
      await login(); // Espera a que se complete el inicio de sesión antes de continuar
    }

    async function login() {
    try {
        loading.value = true;

        // Hacer la solicitud al nuevo endpoint de autenticación con verificación
        const response = await fetch(
            `${proxy.$dominio}/wp-json/custom/v1/auth-verify`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: username.value, // Asegúrate de usar el email aquí
                    password: password.value,
                }),
                credentials: 'include'
            },
        );

        const data = await response.json();
        loading.value = false;

        if (response.ok) {
            // Procesar el token si la autenticación es exitosa
            token.value = data.token;
            //console.log(token.value);

            // Obtener los datos del usuario autenticado
            const userResponse = await fetch(
                `${proxy.$dominio}/wp-json/wp/v2/users/me`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token.value}`,
                    },
                }
            );

            const userData = await userResponse.json();
            if (userResponse.ok) {
                // Asignar los datos del usuario autenticado
                const user = {
                    name: userData.meta.first_name,
                    id: userData.id,
                    token: token.value,
                    profileImage: userData.meta.profile_image,
                };
                store.commit('setAuthenticated', { user });
                router.push("/PagePanel");
            } else {
                console.error('Error al obtener datos del usuario:', userData.message);
            }
        } else {
            // Verifica si el error es por falta de verificación de correo
            if (data.code === 'email_not_verified') {
                errorMsg.value = 'Debes verificar tu correo electrónico antes de iniciar sesión.';
            } else {
                eserror.value = true;
                errorMsg.value = data.message || "Error desconocido";
            }
            store.commit('clearAuthenticated');
        }
    } catch (error) {
        errorMsg.value = "Error de red. Inténtalo de nuevo.";
        eserror.value = true;
        console.error("Error:", error);
    }
}



    // Devuelve los datos y los métodos que deseas usar en el template
    return {
      visible,
      username,
      password,
      eserror,
      errorMsg,
      submitForm,
      loading,
      setUserName,
      themeClass,
      toggle,
      onToggleChange,
       callback,
       //logintest,
      
    };
  },
};
</script>

<style scoped>
.dark-mode {
  background-color: #1f1f1f; /* Fondo oscuro */
  color: #ffffff; /* Texto claro */
}

.light-mode {
  background-color: #ffffff; /* Fondo claro */
  color: #1f1f1f; /* Texto oscuro */
}
</style>