<template>
    <!-- Appointment Start -->
    <div class="container-xxl py-2">
      <div class="container">
        <div :class="{'dark-mode': themeClass === 'dark', 'light-mode': themeClass === 'light'}">
          <div>
            <div v-if="id">
              <!-- Contenido específico para cuando hay un `slug` -->
              <div class="banner">
    <p class="banner-text">Accede a todos los cursos para mentores de la plataforma</p>
    <p class="banner-text text-orange">Convertite en usuario premium y obtendrás acceso a todos los cursos</p>
    <img class="banner-image" :src="imageSrcBanner" alt="Imagen promocional" />
    <button class="banner-button">Convertirme en premium</button>
  </div>
              <!-- Buscar el curso que coincida con el `slug` proporcionado -->
              <div v-if="selectedCurso">
                <h2>{{ selectedCurso.title }}</h2>
                <div v-html="extractIframe(selectedCurso.content)"></div>
              </div>
              <p v-else>Cargando curso...</p>
            </div>
            <div v-else>
              <!-- Contenido específico para cuando no hay `slug` -->
              <div>
                <div class="banner">
    <p class="banner-text">Accede a todos los cursos para mentores de la plataforma</p>
    <p class="banner-text text-orange">Convertite en usuario premium y obtendrás acceso a todos los cursos</p>
    <img class="banner-image" :src="imageSrcBanner" alt="Imagen promocional" />
    <button class="banner-button">Convertirme en premium</button>
  </div>
    <p class="banner-text text-orange">Lista de cursos</p>
    <!-- Aquí puedes cargar la lista de cursos -->
    <ul class="course-list">
      <li v-for="curso in cursos" :key="curso.id" class="course-card">
        <!-- <a :href="`/${curso.slug}`" class="course-link"> -->
          <a @click.prevent="goToCurso(curso.slug)" class="course-link">
          <div class="image-container">
            <img :src="extractImage(curso.content)" alt="Imagen del curso">
            <div class="course-overlay">
              <div class="course-title">{{ curso.title }}</div>
              <div class="course-price">{{ curso.price || 'Gratis' }}</div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Appointment End -->
  </template>
  
  <script setup>
  import { ref, inject, computed, watch, getCurrentInstance } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  
  const { proxy } = getCurrentInstance();
  const cursos = ref([]);
   //imagenes del baner
  const imageSrcBanner = computed(() => `${proxy.$dominio}/wp-content/themes/template-de-prueba/img/picture.webp`);
  
  // Obtén el ID de los parámetros de la ruta
  const route = useRoute();
  const router = useRouter();
  const id = ref(route.params.id || null);
  
  
 //redirecciono al curso
  function goToCurso(slug) {
  router.push(`/cursosMentores/${slug}`);
}
  // Busca el curso seleccionado por ID
  const selectedCurso = computed(() => {
    return cursos.value.find(cursos => cursos.slug === id.value);
  });
  
  // Extraigo solo el contenido del SCORM
  function extractIframe(content) {
    const iframeMatch = content.match(/<iframe[\s\S]*?<\/iframe>/);
    return iframeMatch ? iframeMatch[0] : '';
  }
  // Extraigo solo la imagen del content.
  // function extractImage(content) {
  //   const imgMatch = content.match(/<img[\s\S]*?\/>/);
  //   return imgMatch ? imgMatch[0].replace(/(width|height)="\d*"/g, '') : '';
  // }
  function extractImage(content) {
    const imgMatch = content.match(/<img[^>]+src="([^">]+)"/);
    return imgMatch ? imgMatch[1] : '';
  }
  // Función para obtener los cursos
  const fetchCursos = async () => {
    try {
      const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/cursos`);
      const data = await response.json();
      // console.log(data);
      cursos.value = data;
    } catch (error) {
      console.error('Error al obtener los cursos:', error);
    }
  };
  
  // Llama a la función para obtener los cursos
  fetchCursos();
  
  // Función para detectar el tema
  const toggle = ref(false);
  
  // Actualiza el ID cuando la ruta cambia
  watch(
    () => route.params.id,
    (newId) => {
      id.value = newId || null;
    },
    { immediate: true }
  );
  
  const themeState = inject("theme");
  const themeClass = computed(() => {
    return themeState.theme === "dark" ? "dark" : "light";
  });
  
  watch(
    themeClass,
    (newClass) => {
      toggle.value = newClass === "light";
    },
    { immediate: true }
  );
  
  const onToggleChange = () => {
    themeState.theme = themeState.theme === "dark" ? "light" : "dark";
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap'); /* O usa 'Montserrat' */

.course-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.course-card {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.course-link {
cursor: pointer;
  display: block;
  text-decoration: none;
  color: inherit;
}
.course-link:hover {
  text-decoration: underline; 
}

.image-container {
  width: 400px;
  height: 400px;
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 400px;
  height: 400px;
  object-fit:cover; /* Ajusta la imagen al contenedor sin distorsionar */
}

.course-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5); /* Fondo negro transparente */
  color: white;
  text-align: center;
}

.course-title {
  font-size: 16px;
  font-weight: bold;
}

.course-price {
  font-size: 16px;
  text-decoration: overline;
  color: rgb(255, 0, 0); /* Color rojo para el precio */
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con 50% de opacidad */
  padding: 4px; /* Opcional: para añadir un poco de espacio entre el texto y el borde del fondo */
  border-radius: 4px; /* Opcional: para bordes redondeados */
}

/* baner para promocion */

.banner {
  background-color: #e0f7fa; /* Celeste claro */
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  padding: 0 20px;
  border-radius: 4px;
  position: relative; /* Necesario para posicionar la imagen flotante */
}

.banner-text {
  color: rgb(4, 4, 4);
  font-size: 24px;
  margin-bottom: 16px; /* Espacio entre el texto y el botón */
  font-family: 'Poppins', sans-serif; /* O 'Montserrat', sans-serif */
  font-weight: 600; /* Grueso */
  letter-spacing: 0.5px; /* Espaciado opcional para mayor legibilidad */
  text-align: center; /* Centra el texto en pantallas pequeñas */
  position: relative; /* Permite usar z-index */
  z-index: 2; /* Coloca el texto por encima de la imagen */
}

.banner-image {
  position: absolute;
  right: 20px; /* Espacio desde el borde derecho */
  width: 200px; /* Tamaño más pequeño para pantallas pequeñas */
  height: 100px; /* Tamaño más pequeño para pantallas pequeñas */
  object-fit: cover; /* Asegura que la imagen cubra el área sin deformarse */
  z-index: 1; /* Coloca la imagen detrás del texto */
}

.banner-button {
  background-color: #007bff; /* Azul */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center; /* Alinea el botón al centro en pantallas pequeñas */
  z-index: 2; /* Asegura que el botón también esté por encima de la imagen */
}

.banner-button:hover {
  background-color: #0056b3; /* Azul más oscuro para el hover */
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
  .banner {
    height: auto; /* Ajusta la altura del banner para pantallas pequeñas */
    padding: 20px; /* Aumenta el padding para más espacio */
    flex-direction: column; /* Asegura que los elementos estén apilados verticalmente */
  }

  .banner-image {
    position: static; /* Quita la posición absoluta */
    width: 100%; /* Imagen ocupa todo el ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    margin-top: 20px; /* Añade un margen superior */
  }

  .banner-button {
    margin-top: 20px; /* Añade un margen superior al botón */
    width: 100%; /* Botón ocupa todo el ancho del contenedor */
  }

  .banner-text {
    font-size: 20px; /* Ajusta el tamaño del texto para pantallas más pequeñas */
  }
}

@media (max-width: 480px) {
  .banner-text {
    font-size: 18px; /* Tamaño de texto más pequeño en pantallas muy pequeñas */
  }

  .banner-button {
    font-size: 14px; /* Tamaño de fuente más pequeño en pantallas muy pequeñas */
  }

  .banner-image {
    width: 150px; /* Tamaño más pequeño para pantallas muy pequeñas */
    height: 75px; /* Tamaño más pequeño para pantallas muy pequeñas */
  }
}

</style>