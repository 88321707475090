<template>
    <div v-if="showModal2" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" :class="modalSize">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-black">Traductor de español - inglés</h5>
            <button type="button" class="btn-close" @click="closeModal2"></button>
          </div>
          <div class="modal-body">
            <div class="translation-section">
              <label for="text-input" class="form-label">Ingresa el texto en español:</label>
              <textarea
                id="text-input"
                v-model="inputText"
                class="form-control"
                rows="4"
                placeholder="Escribe el texto que deseas traducir. Úsa oraciones cortas"
              ></textarea>
              <v-btn 
                @click="translateText" 
                color="primary" 
                :loading="isLoading" 
                :disabled="isLoading || !inputText"
              >
                Traducir Texto
              </v-btn>
  
              <v-divider></v-divider>
  
              <div v-if="isLoading" class="loading-message">
                Traduciendo el texto, por favor espera... La IA está procesando.
              </div>
  
              <v-divider></v-divider>
  
              <h2 style="color: grey;">Traducción:</h2>
              <textarea
                v-if="translatedText"
                v-model="translatedText"
                class="form-control"
                rows="4"
                readonly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="" @click="closeModal2"></div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  // Estado del componente
  const inputText = ref('');        // Texto ingresado por el usuario
  const translatedText = ref('');   // Resultado de la traducción
  const isLoading = ref(false);     // Estado de carga
  const apiUrl = 'https://sinapsisspace-traductor-es-en.hf.space/translate'; 
  const secretKey = 'sinapsis';
//   const showModal2 = ref(false);      // Control de visibilidad del modal
  const modalSize = ref('modal-lg'); // Tamaño del modal
  import FileComponent from '@/components/ia/traductor-es-en.vue';
  const props = defineProps({
  showModal2: Boolean,
  modalSize: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:showModal2']);

const closeModal2 = () => {
  emit('update:showModal2', false);
  translatedText.value = null;
  inputText.value = null;
};
  
  // Función para traducir texto utilizando la API
  async function translateText() {
    if (!inputText.value) return;
  
    isLoading.value = true;
    translatedText.value = '';  // Limpiar la traducción anterior
  
    try {
        const response = await fetch(apiUrl, {
        method: 'POST',
              headers: {
                'Content-Type': 'application/json',
          'X-Secret-Key': secretKey
        },
        body: JSON.stringify({ text: inputText.value }),
      });
           
  
      const data = await response.json();
  
      if (response.ok) {
        translatedText.value = data.translated_text;
      } else {
        console.error('Error en la traducción:', data.error);
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
    } finally {
      isLoading.value = false;
    }
  }
  </script>
  
  <style scoped>
  .loading-message {
    color: grey;
    font-style: italic;
  }
  </style>
  