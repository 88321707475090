<template>
      <PageEntradasPost />
    <muestraUltimaMentoria /> 
</template>

<script>
import { defineComponent } from 'vue';

// Components

import PageEntradasPost from '../components/PageEntradasPost.vue';
import muestraUltimaMentoria from '../components/muestraUltimaMentoria.vue';

export default defineComponent({
  name: 'HomeView',

  components: {

    PageEntradasPost,
    muestraUltimaMentoria,
  },
});
</script>
