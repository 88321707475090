<template>
  <v-container>
    <v-container v-if="loadingComponent" class="text-center">
      <v-btn color="primary" disabled>Cargando...</v-btn>
    </v-container>
    <v-row v-if="!loadingComponent">
      <v-col cols="12" md="6">
        <!-- Contenido de la primera columna -->
        <v-card>
          <v-sheet class="ml-4">
            <!-- Mostrar la imagen de perfil actual si existe -->
            <div class="profile-container">
              <div v-if="profileImageUrl" class="profile-image-container">
                <img
                  :src="profileImageUrl"
                  alt="Profile Image"
                  class="profile-image"
                />
              </div>
            </div>

            <!-- Formulario para subir archivo -->
            <!-- <form @submit.prevent="submitForm" enctype="multipart/form-data">
  <input type="file" @change="onFileChange" ref="fileInput" />
  <!-- El botón de enviar puede ser opcional si se carga automáticamente -->
  <!-- <button type="submit" :disabled="loading">Subir Archivo</button> 
       </form> -->
      
    <button type="button" class="btn btn-primary" @click="showModal = true">
      Subir imagen de perfil
    </button>

    
  
            <!-- Formulario principal -->
            <v-form ref="miForm" validate-on="submit lazy">
              <v-text-field v-model="nombre" label="Nombre"></v-text-field>
              <v-text-field v-model="apellido" label="Apellido"></v-text-field>
              <v-text-field v-model="edad" label="Edad"></v-text-field>
              <v-text-field v-model="ubicacion" label="Ubicación"></v-text-field>
              <v-text-field v-model="idioma" label="Idioma"></v-text-field>
            </v-form>
          </v-sheet>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <!-- Contenido de la segunda columna -->
        <v-card>
          <v-textarea
            v-model="descripcion"
            label="Descripción Personal"
            rows="8"
          />
          <div class="inputTag">
            <div class="tags">
              <v-chip
                v-for="(tag, index) in tags"
                :key="index"
                close
                @click:close="deleteTag(tag)"
                class="my-2"
              >
                {{ tag }}
                <v-icon
                  class="ml-2"
                  @click="deleteTag(tag)"
                  style="cursor: pointer"
                >
                  mdi-close
                </v-icon>
              </v-chip>
            </div>
            <!-- Formulario para añadir tags -->
            <v-form @submit.prevent="tagSubmit">
              <v-text-field
                v-model="currentValue"
                label="Añadir industrias"
                append-inner-icon="mdi-send"
                @click:append-inner="tagSubmit"
              ></v-text-field>
            </v-form>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Botón Actualizar -->
    <v-row>
      <v-col>
        <v-btn
          v-if="!loadingComponent"
          :loading="loading"
          class="mt-2 text-blue"
          text="Actualizar"
          @click="submit"
          block
        ></v-btn>
      </v-col>
    </v-row>
    
    <div v-if="showModal" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-black">Subir imagen de perfil</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <file-component @close-modal="closeModal" />
        </div>
      </div>
    </div>
    <div class="" @click="closeModal"></div>
  </div>
   
  </v-container>
</template>

<script setup>
import { ref, getCurrentInstance,computed } from "vue";
import Cookies from "js-cookie";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import FileComponent from '@/components/file.vue';

const store = useStore();
const router = useRouter();
const { proxy } = getCurrentInstance();
const userData = ref(null);
const eserror = ref(false);
const errorMsg = ref("");
// const profileImageUrl = ref(null);
const selectedFile = ref(null);
const tags = ref([]);
const loading = ref(false);
const nombre = ref("");
const apellido = ref("");
const edad = ref("");
const ubicacion = ref("");
const idioma = ref("");
const descripcion = ref("");
const currentValue = ref("");
const loadingComponent = ref(true);
const showModal = ref(false);



const emit = defineEmits(["avatar-updated"]);

const profileImageUrl = computed(() => store.getters.userProfileImage);

const closeModal = () => {
  showModal.value = false;
};

const modalSize = computed(() => {
  return window.innerWidth < 768 ? 'modal-sm' : 'modal-lg';
});

// Función para cargar la imagen de perfil
async function loadProfileImage() {
  try {
    const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    const data = await response.json();

    if (response.ok) {
      profileImageUrl.value = data.meta.profile_image;
      emit("avatar-updated", data.meta.profile_image);
    } else {
      profileImageUrl.value = null;
      console.error("Error al cargar la imagen de perfil:", data.message);
      alert("Cerrada la sesión, vuelve a iniciarla.");
      //router.push("/login");
    }
  } catch (error) {
    profileImageUrl.value = null;
    console.error("Error de red al cargar la imagen de perfil:", error);
  }
}

// Manejador de cambio de archivo
const onFileChange = (event) => {
  selectedFile.value = event.target.files[0];
  if (selectedFile.value) {
   // submitForm(); // Llamar a submitForm automáticamente
  }
};

// Función para subir el formulario
const submitForm = async () => {
  if (!selectedFile.value) {
    errorMsg.value = "Por favor selecciona un archivo.";
    return;
  }

  loading.value = true;
  errorMsg.value = "";

  const formData = new FormData();
  formData.append("file", selectedFile.value);

  try {
    const response = await fetch(
      `${proxy.$dominio}/wp-json/custom/v1/upload_profile_image`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        body: formData,
      },
    );

    const data = await response.json();

    if (response.ok) {
      // console.log(data);
      profileImageUrl.value = data.profile_image;
      store.commit('updateProfileImage', profileImageUrl.value);
    } else {
      errorMsg.value = data.message || "Error al subir archivo";
    }
  } catch (error) {
    errorMsg.value = "Error de red. Inténtalo de nuevo.";
  } finally {
    loading.value = false;
  }
};

// Función para enviar el formulario de actualización
const submit = async () => {
  store.commit('updateUserName', nombre.value);
  loading.value = true;
    try {
    const tagsFormatted = tags.value.map((tag) => ({ value: tag }));
    await actualizarDatos(
      validarDatos(nombre.value),
      validarDatos(apellido.value),
      validarDatos(ubicacion.value),
      validarDatos(idioma.value),
      validarDatos(edad.value),
      validarDatos(descripcion.value),
      tagsFormatted,
    );
  } finally {
    loading.value = false;
  }
};

// Función para actualizar los datos del usuario
async function actualizarDatos(
  nombre,
  apellido,
  ubicacion,
  idioma,
  edad,
  descripcion,
  tags,
) {
  try {
    const tagsJSON = JSON.stringify(tags.map((tag) => ({ value: tag.value })));
    const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/users/me`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      body: JSON.stringify({
        description: descripcion,
        meta: {
          first_name: nombre,
          last_name: apellido,
          user_ubicacion: ubicacion,
          user_idioma: idioma,
          user_edad: edad,
          user_tags: tagsJSON, // Asegúrate de enviar solo los valores de los tags
        },
      }),
    });
    const data = await response.json();
    //console.log(data);

    if (response.ok) {
      userData.value = data;
      eserror.value = false;
      errorMsg.value = "Datos actualizados correctamente";
      fetchUsuarioDatos();
    } else {
      eserror.value = true;
      errorMsg.value = data.message || "Error desconocido";
      console.log("No se pudo actualizar los datos del usuario:", data.message);
    }
  } catch (error) {
    errorMsg.value = "Error de red. Inténtalo de nuevo.";
    eserror.value = true;
    console.error("Error al actualizar los datos del usuario:", error);
  }
}

// Función para agregar una etiqueta
const tagSubmit = () => {
  if (currentValue.value) {
    const tagsValido = validarDatos(currentValue.value);
    tags.value.push(tagsValido);
    currentValue.value = "";
  }
};

// Función para eliminar una etiqueta
const deleteTag = (tag) => {
  tags.value = tags.value.filter((item) => item !== tag);
};

// Cargar datos del usuario al inicio
usuarioDatos();
loadProfileImage();

// Función para obtener y cargar los datos del usuario
function usuarioDatos() {
  fetchUsuarioDatos();
}

// Función para solicitar datos del usuario al servidor
async function fetchUsuarioDatos() {
  try {
    const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    const data = await response.json();
    //console.log(data);
    if (response.ok) {
      userData.value = data;
      nombre.value = data.meta.first_name || "";
      apellido.value = data.meta.last_name || "";
      edad.value = data.meta.user_edad || "";
      descripcion.value = data.description || "";
      ubicacion.value = data.meta.user_ubicacion || "";
      idioma.value = data.meta.user_idioma || "";
      //tags.value = data.meta.industries || [];
      // Ejemplo de user_tags recibido del API
      const user_tags = data.meta.user_tags || [];
      // Convertir la cadena JSON a un array de objetos
      const parsedUserTags = JSON.parse(user_tags);
      // Obtener un array de valores de tags
      tags.value = parsedUserTags.map((tag) => tag.value);
      // console.log(tags.value);
    } else {
      eserror.value = true;
      errorMsg.value = data.message || "Error desconocido";
    }
  } catch (error) {
    errorMsg.value = "Error de red. Inténtalo de nuevo.";
    eserror.value = true;
  }
  loadingComponent.value = false;
}

//funcion para validar y formatear datos del form
const validarDatos = (valor) => {
  // Validar caracteres permitidos (por ejemplo, solo letras, espacios y apóstrofes)
  const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s.,]+$/; // Expresión regular para permitir letras, espacios y apóstrofes

  if (!regex.test(valor)) {
    // alert('El campo debe contener solo letras, espacios y apóstrofes.');
    return "";
  }
  if (valor.length > 1000) {
    // alert('El campo debe contener solo letras, espacios y apóstrofes.');
    return "";
  }
  // Evitar inyección SQL (enfoque básico para ejemplo)
  if (valor.includes("'")) {
    // alert('El campo no debe contener caracteres especiales como comillas simples.');
    return "";
  }

  // Convertir la primera letra a mayúscula
  return valor.charAt(0).toUpperCase() + valor.slice(1).toLowerCase();
};
</script>

<style scoped>
.inputTag {
  margin-top: 20px;
}
/* .inputTag {
  display: inline-flex;
  border: solid 1px #000;
} */

.tag {
  display: flex;
  gap: 3px;
  padding: 5px;
}
.tags {
  padding: 5px;
  gap: 5px;
  align-items: center;
}

.inputTag .input {
  border: none;
  outline: none;
  padding: 0 5px;
}

.chip {
  display: flex;
  align-items: center;
  max-width: 100%;
}

.chip-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(
    100% - 24px
  ); /* Adjust this value based on the width of the close icon */
}

.close-icon {
  flex-shrink: 0;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-image-container {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px; /* Espacio opcional debajo de la imagen */
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal.fade.show {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 90%;
  max-width: 600px;
}

.modal-dialog.modal-lg {
  max-width: 600px;
}
.modal-dialog.modal-sm {
  max-width: 90%;
}
.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s;
}
.modal-enter, .modal-leave-to /* .modal-leave-active en versiones anteriores de Vue */ {
  opacity: 0;
}
.modal-backdrop.fade.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}
</style>
