<template>
    <div>
      <p v-if="loading">Verificando tu cuenta...</p>
      <p v-if="error">{{ error }}</p>
      <p v-if="success">{{ successMessage }}</p>
      <button v-if="success" @click="goToLogin">Ir a Iniciar Sesión</button>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  
  const router = useRouter();
  const route = useRoute();
  const loading = ref(true);
  const error = ref('');
  const success = ref(false); // Definir success
  const successMessage = ref('');
  
  const { user_id, token } = route.query;
  
  onMounted(async () => {
    if (user_id && token) {
      try {
        // Realiza la solicitud al endpoint de verificación
        const response = await fetch(`https://sinapsismentoring.com/wp-json/custom/v1/verify-email?user_id=${user_id}&token=${token}`);
        const data = await response.json();
  
        if (response.ok) {
          successMessage.value = data.message;
          success.value = true;
        } else {
          error.value = data.message || 'Error al verificar la cuenta.';
        }
      } catch (err) {
        console.error('Error:', err); // Log de error para depuración
        error.value = 'Error de red. Inténtalo de nuevo.';
      } finally {
        loading.value = false;
      }
    } else {
      error.value = 'Datos de verificación no válidos.';
      loading.value = false;
    }
  });
  
  function goToLogin() {
    router.push('/login');
  }
  </script>
  


  