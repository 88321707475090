<template>    
        <v-container>
          <v-card>
            <v-card-title>
              <h3>{{ stepTitle }}</h3>
              <v-spacer></v-spacer>
              <div v-if="currentStep < 5">Paso {{ currentStep }} de {{ totalSteps - 1 }}</div>
            </v-card-title>
            <v-card-subtitle class="form-step">
              <v-form>
                <div v-if="currentStep === 1">
                  <v-text-field v-model="courseTitle" label="Título de la mentoria"
				  :error-messages="[errors.courseTitle]"
				  ></v-text-field>
                </div>
                <div v-if="currentStep === 2">
                  <v-select v-model="classCount" :items="classOptions" label="Cantidad de modulos"></v-select>
                </div>
				<div v-if="currentStep === 2">
  <div v-for="n in parseInt(classCount)" :key="n">
    <v-text-field v-model="classTitles[n-1]" :label="`Tema de modulo ${n}`"
	:error-messages="[errors.courseClass]"
	></v-text-field>
  </div>
</div>
                <div v-if="currentStep === 3">
                  <v-select v-model="costType" :items="costOptions" label="Costo"
				  :error-messages="[errors.courseOptions]"
				  ></v-select>
                </div>
				<div v-if="currentStep === 3 && costType === 'Con costo'">
					<v-text-field v-model="costPrice" label="Precio en moneda de tu país"></v-text-field>
				</div>
                <div v-if="currentStep === 4">
                  <div ref="editorContainer" class="editor-container"></div>
                </div>
				<div v-if="currentStep === 5">
				  <div class="text-dark">Procesando datos</div>
				  <v-text-field v-model="sendingMessage" label="Enviando"></v-text-field>
				</div>
              </v-form>
            </v-card-subtitle>
            <v-card-actions  v-if="currentStep !== 5">
              <v-btn  @click="prevStep" :disabled="currentStep === 1">Volver</v-btn>
              <v-btn @click="nextStep">
                {{ currentStep === 4 ? 'Publicar' : 'Siguiente' }}
              </v-btn>
              <v-btn @click="cancelForm" class="ml-2" color="red">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-container> 
   </template>

  <script>
    import { ref, computed, nextTick, watch, reactive, onMounted,getCurrentInstance} from  "vue";
    import Cookies from "js-cookie";
    export default {
      name: "crear",
      setup() {
        const { proxy } = getCurrentInstance();
        const currentStep = ref(1);
        const totalSteps = 5;
        const courseTitle = ref('');
        const classCount = ref('1');
		const classTitles = reactive([
      '', // Primer título de clase
      '', // Segundo título de clase
      '',  // Tercer título de clase
	  '', //cuarta clase
	]);
        const costType = ref('');
		const costPrice = ref('0');
        const costOptions = ['Gratis', 'Con costo'];
		const classOptions = ['1', '2', '3', '4'];
        const editor = ref(null);
        const isEditorInitialized = ref(false);
        const previousStep = ref(null);
		const errors = reactive({courseTitle: '',courseClass: '',courseOptions: ''});
		const sendingMessage = ref('');
        
		
		watch(currentStep, (newStep) => {
  if (newStep === 5) {
    finalizeForm();
  }
});

		const validateStep = () => {
      let isValid = true;
      errors.courseTitle = '';
	  errors.courseClass = '';
	  errors.courseOptions = '';

      //verificacion del paso 1
	  if (currentStep.value === 1) {
               
        if (!courseTitle.value) {
          errors.courseTitle = 'El título es obligatorio.';
          isValid = false;
        } else if (courseTitle.value.length > 100) {
          errors.courseTitle = 'El título NO puede tener más de 100 caracteres.';
          isValid = false;
         }
		}
		//verificacion del paso 2
		if (currentStep.value === 2) {
               
         if (!classTitles[0]) {
         errors.courseClass = 'El nombre del tema no puede estar vacio';
          isValid = false;
        }
		 const invalidEntries = classTitles.filter(title => typeof title !== 'string' || title.length > 100);
          
          if (invalidEntries.length > 0) {
            errors.courseClass = 'El titulo del tema no puede tener más de 100 caracteres.';
            isValid = false;
          }
		  }
		//verificacion del paso 3
		 if (currentStep.value === 3) {
		 if (costType.value === '') {
         errors.courseOptions = 'Debes seleccionar una opción';
          isValid = false;
        }
		}
		//devuelve true or false si esta validado
	     return isValid;
    };
		
		const stepTitle = computed(() => {
          switch (currentStep.value) {
            case 1: return 'Título del la mentoria';
            case 2: return 'Cantidad de modulos';
            case 3: return 'Tipo de mentoria';
            case 4: return 'Desarrolla la mentoria';
			case 5: return 'Finalizando proceso';
            default: return '';
          }
        });

        const isNextStepEnabled = computed(() => {
          // Habilita el botón "Siguiente" si no es el paso 4 o si el editor está inicializado
          return currentStep.value !== 4 || isEditorInitialized.value;
        });

        const nextStep = () => {
          if (currentStep.value < totalSteps ) {
            previousStep.value = currentStep.value; // Guardar el paso anterior
            if (validateStep()) {
        currentStep.value++;
      }
            if (currentStep.value === 4 && !isEditorInitialized.value) {
              initializeEditor();
            }
          } else {
            finalizeForm();
          }
        };

        const prevStep = () => {
          if (currentStep.value > 1) {
            previousStep.value = currentStep.value; // Guardar el paso actual antes de cambiar
            currentStep.value--;
            if (previousStep.value === 4 && currentStep.value === 3) {
             destroyEditor();
            }
          }
        };

        const cancelForm = () => {
          console.log('Formulario cancelado');
          resetForm();
        };

        const finalizeForm = () => {
          const formData = {
            title: courseTitle.value,
            classCount: classCount.value,
			classTitles: classTitles,
            costType: costType.value,
			price: costPrice.value,
            classContent: editor.value ? editor.value.html.get() : ''
          };
          console.log('Datos del formulario:', formData);
			actualizarDatos(formData);
          sendingMessage.value = 'Formulario enviado';
        };

        const resetForm = () => {
          currentStep.value = 1;
          courseTitle.value = '';
          classCount.value = '';
		  costType.value = '';
		  costPrice.value = '0';
          destroyEditor();
        };

        const destroyEditor = () => {
          if (editor.value) {
            editor.value.destroy();
            editor.value = null;
            isEditorInitialized.value = false;
          }
        };

        const initializeEditor = () => {
  nextTick(() => {
    const editorContainer = document.querySelector('.editor-container');
    if (editorContainer) {
      if (editor.value) {
        destroyEditor(); // Asegúrate de destruir cualquier instancia existente del editor
      }
      editor.value = new FroalaEditor(editorContainer, {
        toolbarButtons: {
          'moreText': {
            'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
          },
          'moreParagraph': {
            'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
          },
          'moreRich': {
            'buttons': ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR', 'cohere', 'char_counter']
          },
          'moreMisc': {
            'buttons': [ 'insertImage', 'insertFile', 'insertVideo', 'undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help']
          }
                },
  // Archivos de imagenes
                imageUploadURL: '/wp-content/themes/template-de-prueba/js/editor/upload_image.php',
        inlineMode: false,
	   language: 'es',
	   charCounterMax: 5000,
     key: '1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==',
     attribution: false, // to hide "Powered by Froala"
//Archivos de texto
	fileUploadURL: '/wp-content/themes/template-de-prueba/js/editor/upload_file.php',
    fileMaxSize: 20 * 1024 * 1024,    //maximo 5mb
//archivos de video
	videoUploadURL: '/wp-content/themes/template-de-prueba/js/editor/upload_video.php',
	videoMaxSize: 50 * 1024 * 1024, //maximo 50mb
        events: {
          'contentChanged': () => {
            // Handle content change if needed
          }
        }
      });
      isEditorInitialized.value = true;
    }
  });
};

onMounted(() => {
        // Cargar CSS
        const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/editor/css/froala_editor.pkgd.css';
      document.head.appendChild(link);
    });

    // Función para cargar scripts secuencialmente
const loadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = callback;
  document.head.appendChild(script);
};

// Ejemplo de uso para cargar múltiples scripts
onMounted(() => {
  // Cargar el script principal de Froala Editor
  loadScript('/editor/froala_editor.min.js', () => {
    // Una vez que Froala Editor se ha cargado, cargar el plugin
    loadScript('/editor/js/plugins/font_size.min.js', () => {
      loadScript('/editor/js/plugins/image.min.js', () => {
        loadScript('/editor/js/plugins/font_family.min.js', () => {
          loadScript('/editor/js/plugins/char_counter.min.js', () => {
            loadScript('/editor/js/plugins/file.min.js', () => {
              loadScript('/editor/js/plugins/video.min.js', () => {
                loadScript('/editor/js/languages/es.js', () => {
                  // Añadir icono y comando personalizado
        FroalaEditor.DefineIcon('cohere', { NAME: 'help', SVG_KEY: 'help' });
        FroalaEditor.RegisterCommand('cohere', {
          title: 'Preguntar a IA',
          focus: false,
          undo: false,
          refreshAfterCallback: false,
          callback: async function () {
            const COHERE_API_KEY = 'GE9s5Jih07NEyTd3AYpNQN8sW9cioRugUAwGbKMj';
            const data = {
              model: 'command-xlarge-nightly',
              prompt: this.selection.text(),
              max_tokens: 556,
              temperature: 0.5,
              k: 1,
              stop_sequences: [],
            };
            // Make the API call to Cohere
            const response = await fetch('https://api.cohere.ai/generate', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${COHERE_API_KEY}`
              },
              body: JSON.stringify(data)
            });
            const result = await response.json();
            // Extract the text from the response
            const suggestions = result.text;
            // Insert the suggestion into selected text
            this.html.insert(suggestions);
          }
        });
      // Una vez que todos los scripts se han cargado, inicializar el editor
      initializeEditor();
    });
    });
    });
    });
    });
  });
    });
  });
});

// Función para actualizar los datos del usuario
async function actualizarDatos(data) {
  try {
    // const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/users/asasd`, {
      const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/create-post`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("token")}`,      
      },
      body: JSON.stringify(data),
    });
    
    const responseData = await response.json();

    if (response.ok) {
      console.log(responseData);
     // eserror.value = false;
      //errorMsg.value = "Datos actualizados correctamente";
     
    } else {
      //eserror.value = true;
      //errorMsg.value = responseData.message || "Error desconocido";
      //errors.courseApi = responseData.message || "Error desconocido";
      console.log("No se pudo actualizar los datos del usuario:", responseData.message);
    }
  } catch (error) {
   // errorMsg.value = "Error de red. Inténtalo de nuevo.";
   // eserror.value = true;
    //errors.courseApi = "Error de red. Inténtalo de nuevo.";
    console.error("Error al actualizar los datos del usuario:", error);
  }
}
//fin de funcion api

return {
          currentStep,
          totalSteps,
          courseTitle,
          classCount,
		  classTitles,
		  classOptions,
          costType,
          costPrice,
		  costOptions,
          editor,
          stepTitle,
          nextStep,
          prevStep,
          cancelForm,
          finalizeForm,
          isNextStepEnabled,
		  errors,
		  sendingMessage,
		 
        };
      }
    };
  </script>

