<!-- ShareModal.vue -->
<template>
    <div v-if="isOpen" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Compartir en:</h2>
        <a :href="facebookShareUrl" target="_blank">Facebook</a>
        <a :href="twitterShareUrl" target="_blank">Twitter</a>
        <a :href="linkedinShareUrl" target="_blank">LinkedIn</a>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    url: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  });
  
  const emit = defineEmits()
  const closeModal = () => {
    emit('close');
  };
  
  const userProfileUrl = encodeURIComponent(props.url);
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${userProfileUrl}`;
  // const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${props.url}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${userProfileUrl}`;
  const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${userProfileUrl}`;
  </script>
  
  <style scoped>
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
  }
  .close {
    cursor: pointer;
  }
  </style>
  