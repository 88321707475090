<template>
    <div>
      <button @click="openModal">
        <div class="icon-container">
          <i class="fab fa-facebook"></i>
          <i class="fab fa-twitter"></i>
          <i class="fab fa-linkedin"></i>
        </div>
      </button>
      <ShareModal :url="url" :isOpen="isModalOpen" @close="closeModal" />
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import ShareModal from '@/components/shareModal.vue';
  
  const props = defineProps({
    url: {
      type: String,
      required: true,
    },
  });
  
  const isModalOpen = ref(false);
  
  const openModal = () => {
    isModalOpen.value = true;
  };
  
  const closeModal = () => {
    isModalOpen.value = false;
  };
  </script>
  
  
<style scoped>
.icon-container {
  display: flex;
  align-items: center;
}

.icon-container i {
  font-size: 24px; /* Aumenta el tamaño del ícono */
  margin-left: 15px; /* Espacio a la izquierda de cada ícono */
}

.icon-container i:first-child {
  margin-left: 0; /* Elimina el margen izquierdo del primer ícono */
}
</style>