<template>
    <v-container fluid class="d-flex justify-center align-center" style="height: 100vh;">
      <div v-if="!meetingStarted" class="text-center">
        <v-row class="mb-3">
          <v-col>
            <v-btn color="primary" @click="createMeeting">Crear sala de reunión</v-btn>
          </v-col>
          <v-col>
            <v-btn color="secondary" @click="joinMeeting">Unirse a sala de reunión</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="showJoinDialog" class="mb-3">
          <v-col>
            <v-text-field
              v-model="joinRoomId"
              label="ID de sala de reunión"
              outlined
            />
          </v-col>
          <v-col>
            <v-btn color="success" @click="joinRoom">Unirse</v-btn>
          </v-col>
        </v-row>
      </div>
      <div id="jaas-container"></div> <!-- Asegúrate de que este div está presente -->
    </v-container>
  </template>
  
  <script setup>
import { ref, nextTick } from 'vue';
import Cookies from "js-cookie";

const showJoinDialog = ref(false);
const joinRoomId = ref('');
const meetingStarted = ref(false);
const userID = Cookies.get("id");

const loadJitsi = async (roomName, isJoining = false) => {
  // Espera hasta que el DOM esté completamente renderizado
  await nextTick();

  const jaasContainer = document.querySelector('#jaas-container');

  if (!jaasContainer) {
    console.error('El contenedor de Jitsi no está disponible');
    return;
  }

  const script = document.createElement('script');
  script.src = 'https://8x8.vc/external_api.js'; // URL del script de Jitsi
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    try {
      // Crear la instancia de JitsiMeetExternalAPI con la roomName correcta
      const api = new JitsiMeetExternalAPI('8x8.vc', {
        roomName: roomName,
        parentNode: jaasContainer,
        lang: 'es',
      });
    } catch (error) {
      console.error('Error al crear la instancia de JitsiMeetExternalAPI:', error);
    }
  };
};

const createMeeting = () => {
  meetingStarted.value = true;
  // Asegúrate de que el nombre de la sala sea correcto y no tenga prefijos repetidos
  const roomName = 'vpaas-magic-cookie-28b588408f9446c9b9895240c00541b9/MentoriasPersonalidazadas' + userID;
  loadJitsi(roomName, false);
};

const joinMeeting = () => {
  showJoinDialog.value = true;
};

const joinRoom = () => {
  let roomId = joinRoomId.value.trim();

  // Eliminar el prefijo 'https://' si está presente
  if (roomId.startsWith('https://')) {
    roomId = roomId.substring(8);
  }

  // Eliminar '8x8.vc/' si está presente
  if (roomId.startsWith('8x8.vc/')) {
    roomId = roomId.substring(7);
  }

  // Verificar si hay un ID válido después de eliminar los prefijos
  if (roomId) {
    meetingStarted.value = true;
    loadJitsi(roomId, true);
  } else {
    console.error('ID de sala de reunión no válido');
  }
};
</script>

  
  
  <style scoped>
  #jaas-container {
    height: 100%;
    width: 100%;
    border: 1px solid black;
  }
  </style>
  