<template>
  <div class="container mt-4">
    <h1 class="mb-4">Carrito de Compras</h1>
    <div v-if="cartItems.length" class="row">
      <div class="col-md-12 mb-4" v-for="item in cartItems" :key="item.id">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ item.name }}</h5>
            <p class="card-text"><strong>Precio:</strong> {{ formatCurrency(item.price) }}</p>
            <p class="card-text"><strong>Cantidad:</strong> {{ item.quantity }}</p>
            <p class="card-text"><strong>Subtotal:</strong> {{ formatCurrency(item.price * item.quantity) }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-4">
        <div class="alert alert-info">
          <h4 class="alert-heading">Total:</h4>
          <p class="mb-0">{{ formatCurrency(total) }}</p>
        </div>
        <button class="btn btn-primary mt-3" @click="goToCheckout">Confirmar y continuar</button>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-warning" role="alert">
        El carrito de compras está vacio.
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const cartItems = computed(() => store.state.cart);
const total = computed(() => {
  return cartItems.value.reduce((sum, item) => sum + item.price * item.quantity, 0);
});

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

const goToCheckout = () => {
  router.push('/checkout');
};
</script>

<style scoped>
.card {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}
.card-body {
  padding: 1.25rem;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}
</style>
