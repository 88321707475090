<template>
  <div class="container mt-4">
    <h1 class="mb-4">Checkout</h1>
    <form @submit.prevent="submitOrder" class="bg-light p-4 rounded">
      <div class="form-group mb-3">
        <label for="firstName">First Name</label>
        <input type="text" id="firstName" class="form-control" v-model="billing.first_name" required />
      </div>
      <div class="form-group mb-3">
        <label for="lastName">Last Name</label>
        <input type="text" id="lastName" class="form-control" v-model="billing.last_name" required />
      </div>
      <div class="form-group mb-3">
        <label for="address1">Address</label>
        <input type="text" id="address1" class="form-control" v-model="billing.address_1" required />
      </div>
      <div class="form-group mb-3">
        <label for="city">City</label>
        <input type="text" id="city" class="form-control" v-model="billing.city" required />
      </div>
      <div class="form-group mb-3">
        <label for="state">State</label>
        <input type="text" id="state" class="form-control" v-model="billing.state" required />
      </div>
      <div class="form-group mb-3">
        <label for="postcode">Postcode</label>
        <input type="text" id="postcode" class="form-control" v-model="billing.postcode" required />
      </div>
      <div class="form-group mb-3">
        <label for="country">Country</label>
        <input type="text" id="country" class="form-control" v-model="billing.country" required />
      </div>
      <div class="form-group mb-3">
        <label for="email">Email</label>
        <input type="email" id="email" class="form-control" v-model="billing.email" required />
      </div>
      <div class="form-group mb-3">
        <label for="phone">Phone</label>
        <input type="tel" id="phone" class="form-control" v-model="billing.phone" required />
      </div>
      <button type="submit" class="btn btn-primary">Place Order</button>
    </form>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const cart = computed(() => store.getters.cartItems || []);

const billing = reactive({
  first_name: '',
  last_name: '',
  address_1: '',
  city: '',
  state: '',
  postcode: '',
  country: '',
  email: '',
  phone: ''
});

const createOrder = async (cart) => {
  const orderData = {
    payment_method: 'paypal',
    payment_method_title: 'PayPal',
    set_paid: true,
    billing: { ...billing },
    line_items: cart.map(item => ({
      product_id: item.id,
      quantity: item.quantity
    }))
  };

  try {
    const response = await fetch('https://yourdomain.com/wp-json/wc/v3/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa('ck_your_consumer_key:cs_your_consumer_secret')
      },
      body: JSON.stringify(orderData)
    });
    if (!response.ok) throw new Error('Network response was not ok');
    return await response.json();
  } catch (error) {
    console.error('Error creating order:', error);
    throw error; // Re-throw the error to handle it in submitOrder
  }
};

const submitOrder = async () => {
  try {
    const order = await createOrder(cart.value);
    // Mostrar mensaje de éxito y redirigir al usuario
    console.log('Order placed successfully:', order);
  } catch (error) {
    // Manejar errores
    console.error('Order submission error:', error);
  }
};
</script>

<style scoped>
.bg-light {
  background-color: #f8f9fa !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
