<template>
    <div>
      <!-- <h1>Post Details</h1> -->
      <!-- <div v-if="loading">Cargando: {{ $route.params.post }}</div>
      <div v-if="error">{{ error }}</div>
      <div v-if="post"> -->
      
<!-- Mentoria Start -->
<div class="container-xxl py-0">
  <div class="container" :class="['bg-' + themeClass, { 'text-white': themeClass === 'dark', 'text-dark': themeClass === 'light', }]">
    <div class="p-3">
      <div class="card mb-3" :class="['bg-' + themeClass, { 'text-white': themeClass === 'dark', 'text-dark': themeClass === 'light', }]" style="border-radius: 5px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
        <h2 class="text-center" style="font-size: 24px;">
          {{ loading ? 'Cargando...' : post.title }}
        </h2>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12 mb-3"> <!-- Columna izquierda -->
          <div class="card" style="border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);" :class="['bg-' + themeClass, { 'text-green': themeClass === 'dark', 'text-blueoscuro': themeClass === 'light', }]">
            <div class="card-body d-flex flex-column align-items-center">
              <!-- Verifica que post y post.author existan antes de renderizar -->
              <a v-if="!loading && post.author && post.author.id" :href="`#/perfil/${post.author.id}`">
                <img class="rounded-circle flex-shrink-0" :src="post.author.meta.profile_image" alt="" style="width: 180px; height: 180px;">
              </a>
              <div class="ms-3" style="text-align: center;">
                <h6 class="mt-5 mb-1">
                  {{ loading ? 'Cargando...' : post.author?.meta?.first_name }}
                </h6>
                <small class="mb-2">Mentor</small>
              </div>
            </div>
            <div style="text-align: left; margin-left: 5px;">
              <ul>
                <p class="card-text" style="margin-bottom: 7px; font-size: 16px;">
                  <strong>Apellido:</strong> {{ loading ? 'Cargando...' : post.author?.meta?.last_name }}
                </p>
                <p class="card-text" style="margin-bottom: 7px; font-size: 16px;">
                  <strong>Costo:</strong> {{ loading ? 'Cargando...' : (post.price.trim() !== '' ? post.price : 'Gratis') }}
                </p>
                <p class="card-text" style="margin-bottom: 7px; font-size: 16px;">
                  <strong>Ubicación:</strong> {{ loading ? 'Cargando...' : post.author?.meta?.user_ubicacion }}
                </p>
                <p class="card-text" style="margin-bottom: 7px; font-size: 16px;">
                  <strong>Idiomas:</strong> {{ loading ? 'Cargando...' : post.author?.meta?.user_idioma }}
                </p>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-12 mb-3"> <!-- Columna del medio -->
          <div class="card h-100" :class="['bg-' + themeClass, { 'text-white': themeClass === 'dark', 'text-dark': themeClass === 'light', }]" style="border-radius: 10px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
            <div class="post-container" style="position: relative; margin: 15px;">
              <div class="additional-info" :class="['bg-' + themeClass, { 'text-dark-pink': themeClass === 'dark', 'text-blueoscuro': themeClass === 'light', }]" style="float: right; width: 50%; background: white; padding: 10px; box-shadow: 0 2px 4px rgba(0,0,0,0.1); margin-left: 15px;">
                <h4>Valoraciones</h4>
                <p>⭐⭐⭐⭐⭐</p>
                <button @click="likePost" class="btn btn-primary">Me Gusta</button>
                <button @click="sharePost" class="btn btn-secondary" style="margin-top: 0px;">Compartir</button>
                <h4 style="margin-top: 10px; text-decoration: underline;">Temario:</h4>
                <!-- Verifica si hay datos en filteredClassTitles -->
                <p v-if="loading">Cargando...</p>
                <p v-else-if="filteredClassTitles.length === 0">No hay datos disponibles</p>
                <p v-else v-for="(classTitle, index) in filteredClassTitles" :key="index">
                  <strong>Modulo {{ index + 1 }}:</strong>
                  {{ classTitle }}
                </p>
              </div>
              <!-- Contenedor con tamaño mínimo para reservar espacio -->
              <div class="post-description" v-html="loading ? '<div style=\'text-align: center; padding: 20px;\'>Cargando...</div>' : cleanedContent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mentoria Fin -->

  </div>
  <!-- </div> -->

  <div class="container my-5">
        <div class="quote-container text-white">
            <div class="quote-icon">
                <i class="fas fa-quote-left"></i>
            </div>
            <p class="quote-text">La investigación demuestra la importancia de tener un mentor para el éxito profesional, con el 80% de los CEO informando haber recibido mentoría y el 93% de las nuevas empresas admitiendo que la mentoría es fundamental para el éxito.</p>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, getCurrentInstance, inject, computed } from 'vue';

const post = ref(null);
const loading = ref(true);
const error = ref('');

const cleanedContent = ref('');
const { proxy } = getCurrentInstance();
const themeState = inject("theme");

const themeClass = computed(() => {
  return themeState.theme === "dark" ? "dark" : "light";
});
const cleanContent = (html) => {
  // Eliminar todos los estilos que contienen width: 600px
  return html.replace(/width\s*:\s*600px\s*;?/gi, 'width: 100%');
};
const filteredClassTitles = computed(() => {
  const titles = post.value?.class_titles || [];
  return Array.isArray(titles) ? titles.filter(title => title.trim() !== '') : [];
});

const fetchPost = async (postSlug) => {
  try {
    const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/mentoria/${postSlug}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      error.value = '';
      switch (response.status) {
        case 401:
          throw new Error('No autorizado: Verifica tus credenciales o token.');
        case 403:
          throw new Error('Prohibido: No tienes permiso para acceder a este recurso.');
        case 404:
          throw new Error('Post No encontrado: Verifica el nombre de la publicación.');
        case 500:
          throw new Error('Error interno del servidor: Inténtalo de nuevo más tarde.');
        default:
          throw new Error(`Error inesperado: ${response.statusText}`);
      }
    }
    error.value = '';
    const data = await response.json();
    cleanedContent.value = cleanContent(data.content);
    post.value = data;

  } catch (err) {
    error.value = '';
    if (err.name === 'TypeError' && err.message.includes('Failed to fetch')) {
      error.value = 'Error de red: No se pudo conectar al servidor.';
    } else {
      error.value = err.message;
    }
  } finally {
         loading.value = false;
      }
};

// Define el postSlug manualmente
const postSlug = "descubre-que-es-sinapsis-mentoring";

// Llama a fetchPost con el postSlug manualmente
fetchPost(postSlug);

</script>



<style scoped>

.post-description {
  min-height: 2000px; /* Altura por defecto para móviles */
}

/* Estilos específicos para pantallas grandes (como PC) */
@media (min-width: 1024px) {
  .post-description {
    min-height: 1400px; /* Altura para pantallas grandes */
  }
}

.post-description video {
  max-width: 100%;
  height: auto;
}
.text-dark-pink {
  color: #019526; /* Ajusta el color según tu preferencia */
}
.text-dark-naranja {
  color: #019526; /* Ajusta el color según tu preferencia */
}.text-blueoscuro {
  color: #441aae; /* Ajusta el color según tu preferencia */
}
.quote-container {
  background: linear-gradient(rgb(0, 123, 255), rgb(0, 100, 200)); /* Degradado sutil */
            border-radius: 15px;
            display: flex;
            align-items: center;
            padding: 2rem;
        }
        .quote-icon {
            font-size: 3rem;
            margin-bottom: 4rem;
            margin-right: 1rem;
            color: orange;
        }
        .quote-text {
            font-size: 1.2rem;
            font-style: italic;
            
        
        }
</style>
