<template>
  <div v-if="showModal" class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-black">Remover fondo de imagen</h5>
          <button type="button" class="btn-close" @click="closeModal"></button>
        </div>
        <div class="modal-body">
          <div class="upload-section">
            <label for="custom-avatar-file-input" class="custom-avatar-file-label">Seleccionar imagen</label>
            <input id="custom-avatar-file-input" type="file" accept="image/*" class="custom-avatar-file-input" @change="handleFileChange" />
            
            <v-btn 
              @click="processImage" 
              color="primary" 
              :loading="isLoading" 
              :disabled="isLoading"
            >
              Procesar Imagen
            </v-btn>
            <v-divider></v-divider>
            <div v-if="isLoading" class="loading-message">
              La IA está procesando imagen. Esto puede tardar un momento...
            </div>
            <v-divider></v-divider>
            <h2 style="color: grey;">Vista previa:</h2>
            <v-img v-if="previewSrc" :src="previewSrc" max-width="300"></v-img>
            <h2 style="color: grey;">Resultado:</h2>
            <v-img v-if="resultSrc" :src="resultSrc" max-width="300"></v-img>
            <v-btn v-if="resultSrc" :href="resultSrc" download="imagen_procesada.jpg" color="secondary">
              Descargar Imagen
            </v-btn>
            
            <!-- Agregar fondos predeterminados -->
            <h2 style="color: grey;">Seleccionar Fondo:</h2>
            <div class="background-options">
              <v-img 
                v-for="(background, index) in backgrounds" 
                :key="index" 
                :src="background" 
                max-width="100" 
                @click="applyBackground(background,resultSrc)"
                class="background-thumbnail"
              />
            </div>
            <v-divider></v-divider>
            <h2 style="color: grey;">Imagen con Fondo:</h2>
    <v-img v-if="combinedImage" :src="combinedImage" max-width="300"></v-img>
    <v-btn v-if="combinedImage" :href="combinedImage" download="imagen_con_fondo.png" color="secondary">
      Descargar Imagen
    </v-btn>
  </div>
          <file-component @close-modal="closeModal" />
        </div>
      </div>
    </div>
    <div class="" @click="closeModal"></div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import FileComponent from '@/components/ia/quitaFondo.vue';
  const props = defineProps({
  showModal: Boolean,
  modalSize: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:showModal']);

const closeModal = () => {
  emit('update:showModal', false);
  previewSrc.value = null;
  resultSrc.value = null;
  combinedImage.value = null;
  imageFile.value = null;
};
  
  // Reactive variables
const modalSize = ref('');
const finalImageSrc = ref('');

  const isLoading = ref(false); 
  const previewSrc = ref(null);
  const resultSrc = ref(null);
  const imageFile = ref(null);
  const apiUrl = 'https://eldirectorweb-prueba.hf.space/process_image'; // Cambia esto a tu URL de API
  const secretKey = 'sinapsis';
  const combinedImage = ref(null);
  // Functions
// Fondos predeterminados
const backgrounds = ref([
   require('@/assets/ia/backgrounds/fondo1.jpg'),
   require('@/assets/ia/backgrounds/fondo2.jpg'),
   require('@/assets/ia/backgrounds/fondo3.jpg'),
   require('@/assets/ia/backgrounds/fondo4.jpg'),
  // Agrega más fondos según sea necesario
]);

// Función para aplicar fondo seleccionado
const applyBackground = (backgroundSrc, overlaySrc) => {
  // Verifica si overlaySrc es null
  if (overlaySrc === null) {
    alert('Debes procesar la imagen primero');
    return; // Termina la función si no hay imagen superpuesta
  }

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Cargar la imagen de fondo
  const background = new Image();
  background.src = backgroundSrc; // Ruta local o base64
  background.crossOrigin = 'Anonymous'; // Agregar CORS si la imagen es de otro origen
  background.onload = () => {
    // Establecer el tamaño del canvas al tamaño de la imagen de fondo
    canvas.width = background.width;
    canvas.height = background.height;

    // Dibujar la imagen de fondo
    context.drawImage(background, 0, 0);

    // Cargar la imagen superpuesta
    const overlay = new Image();
    overlay.src = overlaySrc; // Ruta local o base64
    overlay.crossOrigin = 'Anonymous'; // Agregar CORS si la imagen es de otro origen
    overlay.onload = () => {
      // Calcular el nuevo tamaño para la imagen superpuesta (70% del tamaño del fondo)
      const overlayWidth = background.width * 0.8;
      const overlayHeight = (overlay.height / overlay.width) * overlayWidth;

      // Calcular la posición para centrar la imagen superpuesta
      const x = (canvas.width - overlayWidth) / 2;
      const y = (canvas.height - overlayHeight) / 2;

      // Dibujar la imagen superpuesta en el canvas con el tamaño ajustado
      context.drawImage(overlay, x, y, overlayWidth, overlayHeight);

      // Agregar marca de agua
      context.font = '48px Arial'; // Tamaño y tipo de fuente
      context.fillStyle = 'rgba(255, 255, 255, 0.5)'; // Color y opacidad del texto
      context.textAlign = 'center'; // Alineación del texto
      context.textBaseline = 'middle'; // Línea base del texto
      const text = 'Sinapsis';
      const textX = canvas.width - 100; // Posición horizontal del texto (ajustar según sea necesario)
      const textY = canvas.height - 50; // Posición vertical del texto (ajustar según sea necesario)
      context.fillText(text, textX, textY); // Dibujar el texto en el canvas

      // Convertir el canvas a una imagen base64
      combinedImage.value = canvas.toDataURL('image/png');
      
      // Aquí puedes hacer lo que necesites con la imagen combinada, como mostrarla o guardarla
      //console.log(combinedImage.value);
    };

    overlay.onerror = (err) => {
      console.error('Error al cargar la imagen superpuesta:', err);
    };
  };

  background.onerror = (err) => {
    console.error('Error al cargar la imagen de fondo:', err);
  };
};



// Ejemplo de uso con una imagen local y la imagen base64 pequeña
//const localImage = require('@/assets/pared-ladrillo.avif'); // Imagen local
//const base64Image = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/7eJAAAAAElFTkSuQmCC';

//applyBackground(localImage, base64Image);


// Llamada a la función con rutas relativas a las imágenes en la carpeta 'assets'
//applyBackground(require('@/assets/pared-ladrillo.jpg'), require('@/assets/otra-imagen.png'));


  const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Verifica si el archivo es una imagen
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validImageTypes.includes(file.type)) {
      alert('Por favor, sube una imagen válida (JPEG, PNG, GIF)');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      previewSrc.value = e.target.result;
    };
    reader.readAsDataURL(file);
    imageFile.value = file;
  }
};

  
  const processImage = async () => {
    if (!imageFile.value) {
      alert('Por favor selecciona una imagen.');
      return;
    }
    isLoading.value = true;
    const formData = new FormData();
    formData.append('file', imageFile.value);
  
    try {
      resultSrc.value = null; // Limpiar el resultado previo
      console.log('Procesando imagen...');
  
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'X-Secret-Key': secretKey
        }
      });
  
      if (response.ok) {
        const blob = await response.blob();
        resultSrc.value = URL.createObjectURL(blob);
        isLoading.value = false;
      } else {
        console.error('Error en la respuesta de la API:', response.statusText);
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
    }
  };
  
  

  </script>
  
  <style scoped>
  /* Tu estilo aquí, puedes incluir los estilos CSS que has proporcionado anteriormente */
  .background-options {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.background-thumbnail {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}
.background-thumbnail:hover {
  border-color: #007bff;
}
  .loading-message {
  color: grey;
 }
 
  .modal.fade.show {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  width: 90%;
  max-width: 600px;
  max-height: 80vh; /* Ajusta según sea necesario */
  overflow-y: auto;
}

.modal-dialog.modal-lg {
  max-width: 600px;
}
.modal-dialog.modal-sm {
  max-width: 90%;
}
.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: auto;
}
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.3s;
}
.modal-enter, .modal-leave-to /* .modal-leave-active en versiones anteriores de Vue */ {
  opacity: 0;
}
.modal-backdrop.fade.show {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9040;
}

  .upload-section {
    text-align: center;
    max-width: 200px;
  }
  
  #preview, #resultado {
    max-width: 300px;
    margin-bottom: 20px;
  }
 
  /* Mantén tu importación de Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

/* Lista de cursos */
.course-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center; /* Centra las tarjetas para pantallas pequeñas */
}

/* Tarjetas de cursos */
.course-card {
  position: relative;
  width: 100%; /* Cambiar a 100% para que se ajuste al contenedor */
  max-width: 400px; /* Ancho máximo */
  height: auto; /* Permitir que la altura se ajuste */
  border-radius: 8px;
  overflow: hidden;
  flex: 1; /* Las tarjetas ocuparán todo el ancho disponible en móviles */
}

.course-link {
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: inherit;
}

.course-link:hover {
  text-decoration: underline; 
}

.image-container {
  width: 100%; /* Cambiar a 100% para que se ajuste al contenedor */
  height: auto;
  position: relative;
  overflow: hidden;
}

.image-container img {
  width: 100%; /* Imagen se ajustará al ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  object-fit: cover; /* Ajusta la imagen al contenedor sin distorsionar */
}

.course-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5); /* Fondo negro transparente */
  color: white;
  text-align: center;
}

.course-title {
  font-size: 16px;
  font-weight: bold;
}

.course-price {
  font-size: 16px;
  text-decoration: overline;
  color: rgb(255, 0, 0); /* Color rojo para el precio */
  margin-top: 8px;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo negro con 50% de opacidad */
  padding: 4px; /* Opcional: espacio alrededor del texto */
  border-radius: 4px;
}

/* Banner de promoción */
.banner {
  background-color: #e0f7fa; /* Celeste claro */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  border-radius: 4px;
}

.banner-text {
  color: rgb(4, 4, 4);
  font-size: 24px;
  margin-bottom: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  z-index: 2;
}

.banner-image {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: cover;
  z-index: 1;
}

.banner-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center;
}

.banner-button:hover {
  background-color: #0056b3;
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 768px) {
  .banner {
    padding: 20px;
  }

  .banner-text {
    font-size: 20px; /* Ajusta el tamaño del texto */
  }

  .banner-image {
    width: 100%; /* Imagen ocupará todo el ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
  }

  .banner-button {
    margin-top: 20px;
    width: 100%; /* Botón ocupa todo el ancho del contenedor */
  }

  .course-card {
    width: 100%; /* Las tarjetas ocuparán todo el ancho disponible */
  }

  .course-title {
    font-size: 14px; /* Texto más pequeño en pantallas medianas */
  }

  .course-price {
    font-size: 14px;
  }
}

/* Media Queries para pantallas muy pequeñas */
@media (max-width: 480px) {
  .banner-text {
    font-size: 18px; /* Ajusta el tamaño del texto en pantallas muy pequeñas */
  }

  .banner-button {
    font-size: 14px;
  }

  .course-title {
    font-size: 12px; /* Texto más pequeño en pantallas pequeñas */
  }

  .course-price {
    font-size: 12px;
  }
}

</style>
  