<template>
    <div>
      <p>El ID recibido es: {{ $route.params.id }}</p>
      <p v-if="isLoading">Cargando...</p>
      <p v-else>El título recibido es: {{ entrada.title }}</p>
      <!-- Resto del contenido del componente -->
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch, getCurrentInstance } from 'vue';
  import { useRoute } from 'vue-router';
  
  const entrada = ref({});
  const isLoading = ref(false); // Define isLoading como una propiedad del componente
  const route = useRoute();
  const { proxy } = getCurrentInstance(); //proxy para dominio
  
  const obtenerEntrada = async () => {
    isLoading.value = true;
  
    try {
      const postId = route.params.id;
      const response = await fetch(`${proxy.$dominio}/wp-json/wp/v2/posts/${postId}`);
      const data = await response.json();
  
      entrada.value = {
        id: data.id,
        title: data.title.rendered
      };
    } catch (error) {
      console.error('Error al obtener la entrada:', error);
    } finally {
      isLoading.value = false;
    }
  };
  
  onMounted(obtenerEntrada);
  
  watch(() => route.params.id, () => {
    obtenerEntrada();
  });
  </script>
  