<!-- Revisar que las publicaciones se muestren con token verification -->
 <!-- Revisar que las publicaciones se muestren con token verification -->
  <!-- Revisar que las publicaciones se muestren con token verification -->
<template>
    <div class="card-box text-dark">
                     <h4 class="header-title text-dark mb-3">Mis mentorias</h4>
                     <p v-if="loading" disable>Cargando...</p>
                     <p v-if="errorMsj"disable>No se encontraron Mentorias</p>
                                         
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nombre de la mentoria</th>
                                    <th>Fecha de creación</th>
                                    <th>Fecha de expiración</th>
                                    <th>Estado</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(publicacion, index) in publicaciones" :key="publicacion">
          <td>{{ index + 1 }}</td>
          <td>{{ publicacion.titulo }}</td>
          <td>{{ publicacion.fecha }}</td>
          <td>No expira</td>
          <td><span class="label label-info">{{ publicacion.estado }}</span></td>
          <td><button @click="linkToMentoria(publicacion.slug)" type="button" class="btn btn-icon btn-sm waves-effect waves-light btn-success">Ver</button></td>
        </tr>
                               </tbody>
                        </table>
                    </div>
                </div>
</template>

<script setup>
    import { ref, getCurrentInstance, onMounted, computed, } from 'vue';
  import { useStore } from 'vuex';
  import Cookies from "js-cookie";
  import { useRouter } from 'vue-router';

  const router = useRouter();
  const loading = ref(true);
  const errorMsj = ref(false);
  const publicaciones = ref([]);
  const userId = computed(() => store.state.id);
  const store = useStore();
  const { proxy } = getCurrentInstance();

  const linkToMentoria =  (slug) => {
    router.push(`/mentoria/${slug}`);
     
        };

  const obtenerPublicaciones = async () => {
      try {
        const token = Cookies.get('token'); // Obtén el token de las cookies
        // Realiza la solicitud a la API
        const response = await fetch(`${proxy.$dominio}/wp-json/custom/v1/muestra5publicaciones/${userId.value}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // Verifica si la respuesta de la red fue exitosa
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Convierte la respuesta en JSON
        const data = await response.json();

        // Verifica si el código de error indica que no se encontraron mensajes
        if (data.code === 'no_messages_found') {
          console.log('No tienes Publicaciones');
          loading.value = false; // Marca que la carga ha terminado
          publicaciones.value = [];  // Limpia los mensajes
          errorMsj.value = true;
        } else {
          // Si hay mensajes, actualiza el estado y muestra los mensajes
          loading.value = false;
          publicaciones.value = data;
          // console.log(publicaciones.value);
        }
      } catch (error) {
        // Maneja cualquier error que ocurra durante la solicitud
        console.error('Error al obtener los mensajes:', error);
        loading.value = false;
        errorMsj.value = true;
      }
    };

onMounted(() => {
    obtenerPublicaciones();
  });

</script>